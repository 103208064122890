.scrollToBottom {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
  width: 38px;
  height: 38px;
  border-radius: 100%;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: relative;
  cursor: pointer;
}

.newMessages {
  $height: 18px;

  position: absolute;
  top: 0;
  left: 50%;
  min-width: 18px;
  padding: 0 4px;
  height: 18px;
  transform: translate(-50%, -50%);

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: #4778EE;
  color: white;
  border-radius: calc(#{$height} / 2);

  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.4px;

  pointer-events: none;
  user-select: none;
}
