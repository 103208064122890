.paper {
  width: min(872px, 94vw);
  max-width: unset !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 12px !important;

  border-radius: 12px !important;
  background: #272A36 !important;
}

.wrapper {
  padding: 20px 24px;
}

.close {
  position: absolute;
  right: 0;
  top: 0;

  width: 60px !important;
  height: 60px !important;
}

.header {
  padding-right: 50px;
  display: flex;

  &__text {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
  }
}

.description {
  margin-top: 8px;
  margin-bottom: 25px;

  line-height: 18.75px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;

  &__companiesOnly {
    &__icon {
      margin: 0 4px;
      position: relative;
      top: 4px;
    }

    //display: inline-flex;
    color: #E59F42;
  }
}

.cards {
  max-width: 100%;
  display: flex;
  align-items: stretch;
  gap: 16px;
  margin-top: 24px;

  &>* {
    flex: 1 1 0;
  }
}

.roles {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  &__select {
    margin: auto;
    background: #31343F;
    box-shadow: 0px 9px 19px 0px rgba(0, 0, 0, 0.10), 0px 34px 34px 0px rgba(0, 0, 0, 0.09), 0px 77px 46px 0px rgba(0, 0, 0, 0.05), 0px 137px 55px 0px rgba(0, 0, 0, 0.01), 0px 215px 60px 0px rgba(0, 0, 0, 0.00);

    &__item {
      color: #8E8E93;

      &Active {
        //background: #4778EE;
        color: white;
      }
    }
  }
}

.faq {
  &__title {
    margin: 20px 0;

    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
  }

  &__list {
    &>* {
      margin-bottom: 16px;
    }
  }
}

.footer {
  margin-top: 24px;

  display: flex;
  justify-content: center;

  &__close {
    width: 288px;
    max-width: 100%;
  }
}

@media(max-width: 700px) {
  .wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header {
    &__text {
      font-size: 20px;
      font-weight: 500;
      line-height: 23.44px;
    }
  }

  .cards {
    gap: 8px;
    flex-direction: column;
    align-items: stretch;
  }

  .faq {
    &__title {
      margin: 16px 0 8px;
    }

    &__list>* {
      margin-bottom: 8px;
    }
  }

  .description__companiesOnly {
    display: inline-block;

    &__icon {
      margin-left: 0;
    }
  }
}
