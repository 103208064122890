.main {
  padding: 16px 10px;
  border-top: 1px solid #E5E6EE;
  background: #FAFBFC;

  color: #8E8E93;
  font-size: 14px;
  font-weight: 400;

  text-align: center;
}
