.wrapper {
  width: min(100vw, 300px);
  max-width: 100%;
  padding: 5px;
  border-radius: 4px;
  //display: inline-flex;
  //align-items: center;
  //justify-content: space-between;

  position: relative;

  &:hover {
    padding-right: 44px;
    background: rgba(175, 175, 178, 0.13);
    transition: 0.3s background;
  }

  &:hover .delete {
    opacity: 1;
    transition: 0.2s all;
  }
}

.delete {
  opacity: 0;

  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;

  flex-shrink: 0;
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 44px;
  background: transparent;

  border: 0;

}
