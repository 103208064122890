@import '../config.module';

.headerBackdrop {
  height: $header-height;
}

.header {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  height: $header-height;
  border-bottom: 1px solid #E2E2E2;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Fixed {
    position: fixed;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: saturate(50%) blur(7px);
  }
}

.tooltip {
  cursor: auto;
  display: flex;
  padding: 8px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.12);

  &__icon {
    & svg {
      width: 40px !important;
      height: 40px !important;
    }
  }

  &__description {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 332px
  }

  &__close {
    background: transparent;
    border: none;
    height: fit-content;
    cursor: pointer;
  }

  &__arrow {
    position: absolute;
    font-size: 7px;
    top: 0;
    left: 0;
    margin-top: -0.9em;
    width: 3em;
    height: 1em;
    &::before {
      content: "";
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 1em 1em 1em;
      border-color: transparent transparent white transparent;
    }
  }

  & > *:not(:last-child) {
    margin-right: 8px;
  }
}

.companyName {
  display: none;
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  margin: 0 8px;
  color: #000000;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.lang {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #4778EE;
  border: 0;
  background: none;
  cursor: pointer;
  margin: 0 24px;

  &__menu {
    z-index: 999;

    &__content {
      min-width: auto !important;
    }

    &__item {
      box-sizing: border-box;
      padding: 15px 10px 15px 0px !important;
      text-align: center;
      text-decoration: none;
      font-weight: 600;

      &__active {
        text-align: center !important;
        pointer-events: none;

        & > * {
          font-weight: 500;
          color: #4778EE !important;
        }
      }
    }
  }
}


.logoWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  width: $sidebar-collapsed-width;
  height: $header-height;
  user-select: none;
  flex-shrink: 0;
  margin-right: 24px;

  & > * {
    user-select: none;
  }

  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 50%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #E2E2E2;
  }
}

.user {
  height: $header-height;
  width: $header-height;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  background: transparent;
  border: 0;

  cursor: pointer;
}

.menu {
  width: 160px;
  z-index: 1050;
}

.logout {
  color: #E23834 !important;
}

.navLink {
  user-select: none;
  text-decoration: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

.group {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.actions {
  display: flex;
  align-items: center;

  //& > :not(:first-child) {
  //  margin-left: 24px !important;
  //}
}

.help {
  margin-left: 24px;
}

.createCompanyButton {
  border-color: #4778EE !important;
  border-radius: 8px !important;
  font-size: 15px !important;
  font-weight: 500;
}

.createOffer {
  &__button {
    cursor: pointer;
    font-size: 15px !important;
    font-weight: 500;
    box-shadow: 0 49px 20px rgba(71, 120, 238, 0.01),
    0 28px 17px rgba(71, 120, 238, 0.05),
    0 12px 12px rgba(71, 120, 238, 0.09),
    0 3px 7px rgba(71, 120, 238, 0.1),
    0 0 0 rgba(71, 120, 238, 0.1) !important;

    &__text {
      margin-left: 4px;
    }
  }

  &__menu {
    z-index: 999;
    width: 202px;
  }
}

.companySelect__desktop {
  margin-right: 5px;
}

.companyMenu {
  &__description {
    margin-top: 7px;
    margin-bottom: 2px;

    &__label {
      padding-left: 2px !important;
    }
  }

  &__edit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    width: 30px;
    padding-right: 8px;
    margin-left: auto;
    cursor: pointer !important;

    &:hover path {
      fill: #4778ee;
    }
  }

  &__item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 12px;
    cursor: pointer;
    color: #272A36;
    padding-left: 8px;
    margin-bottom: 8px;
    max-width: 100%;

    &__name {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #272A36;
      max-width: 100%;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__role {
      font-size: 9px;
      line-height: 11px;
      color: #8E8E93;
      max-width: 100%;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__info {
      margin-left: 8px;
      max-width: 100%;
      overflow: hidden;
    }

    &:hover {
      background: #F0F4FE;
      color: #4778EE;
    }

    &Active {
      background: #F0F4FE;
      cursor: default;

      .companyMenu__title {
        color: #4778EE;
      }
    }
  }

  &__add {
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #4778EE;
    height: 32px;
    cursor: pointer;
  }

  &__content {
    padding: 16px;
    padding-top: 0;
    width: 199px;
    border-radius: 28px !important;
  }

  &__margin {
    padding: 0 16px 8px;
  }

  &__logo {
    width: 24px;
    height: 24px;
    background: #EBECF2;
    border-radius: 4px;
    flex-shrink: 0;

    &Img {
      width: 24px;
      height: 24px;
    }
  }

  &__title {
    font-family: Roboto, serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
    color: #272A36;
    margin-right: 8px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin-left: 8px;
  }

  &__label {
    width: 100%;
  }

  &__open {
    user-select: none;
    display: flex;
    cursor: pointer;
    align-items: center;
    transition: 0.2s background;
    border-radius: 12px;
    padding: 8px;
    background: #F0F4FE;

    &__info {
      margin-left: 8px;
      width: 108px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__name {
      max-width: 100%;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #272A36;

      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__manage {
      border: 0;
      background: 0;
      display: inline-block;
      color: #4778EE;
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      cursor: pointer;
      transition: 0.2s all;
      max-width: 100%;
      user-select: none;

      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        color: #0b48dc;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    width: 16px;
    height: 16px;
    margin-left: 4px;
    transition: 0.2s all;

    &Open {
      transform: rotate(0deg);
    }
  }
}

.button__icon {
  margin-right: 14px;
}

.hamburger {
  $line-width: 18px;
  $line-height: 1.5px;

  width: 56px;
  height: 100%;

  border: 0;
  background: transparent;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 19px;
  position: relative;

  &__line {
    background: black;
    border-radius: 3px;
    width: $line-width;
    height: $line-height;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    transition: 0.3s all;

    &:first-child {
      top: calc(50% - $line-height * 3 - $line-height / 2);
    }

    &:nth-child(2) {
      opacity: 1;
      top: calc(50% - $line-height / 2);
    }

    &:last-child {
      top: calc(50% + $line-height * 3 - $line-height / 2);
    }
  }

  &Active {
    .hamburger__line:first-child {
      top: calc(50% - $line-height / 2);
      transform: translateX(-50%) rotate(45deg); // translateY(#{($line-width - $line-height * 4) / 4});
    }

    .hamburger__line:nth-child(2) {
      background: white;
      opacity: 0;
    }

    .hamburger__line:last-child {
      top: calc(50% - $line-height / 2);
      transform: translateX(-50%) rotate(-45deg); // translateY(#{($line-width - $line-height * 4) / -4});
    }
  }
}

.avatar {
  min-width: 24px;
}

.avatarHeader {
  min-width: 24px;
}

.spacer {
  margin: auto;
}

.premiumButton {
  margin-right: 16px;
}

.socialLink {
  height: 24px;
  width: 24px;
  //margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F0F4FE;
  border-radius: 50%;
  transition: background .3s;

  &:hover {
    background: #4778EE;

    svg path {
      fill: #fff;
      transition: fill .3s;
    }
  }

  & + & {
    margin-left: 10px;
  }
}

@media (max-width: 1065px) {
  .socialLink {
    display: none;
  }
}

@media (max-width: 700px) {
  .companyName {
    display: -webkit-box;
  }

  .langBlock {
    display: none;
  }

  .lang__menu,
  .companyMenu__list {
    position: fixed !important;
  }

  .socialLink {
    display: flex;
  }
}
