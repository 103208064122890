.paper {
  width: max(400px, 100vw);
  border-radius: 12px !important;
}

.root {
  text-align: center;
  color: #272A36
}

.content {
  padding: 16px 24px;
}

.title {
  font-size: 24px;
  font-weight: 400;
  line-height: 28.13px;
}

.description {
  margin-top: 16px;

  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
}

.topUpButton {
  width: 100%;
}

.appeal {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
}

.accept {
  cursor: pointer;

  border-top-left-radius: 0;
  border-top-right-radius: 0;

  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 64px;

  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;

  border: 0;
  outline: none;

}

.kpp {
  max-width: 50%;
  margin: auto;
  margin-top: 50px;
}
