.paper {
  position: relative;
  width: 460px;
  padding: 24px;
  background: #fff;
  border-radius: 28px !important;
  overflow-x: hidden;
}

.image {
  display: block;
  margin: 0 auto;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background: transparent;
  width: 62px;
  height: 62px;
  cursor: pointer;
}

.title {
  margin: 16px 0 0;
  font-size: 24px;
  font-weight: 400;
  color: #272A36;
}

.descriptionItem {
  display: flex;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #272A36;

  &:last-of-type {
    margin-bottom: 8px;
  }

  svg {
    flex-shrink: 0;
    margin-right: 12px;
  }
}

.button {
  width: 100%;
  margin-top: 16px;
  justify-content: flex-start;

  svg {
    margin-right: 8px;
  }
}

