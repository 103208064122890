.root {
  position: relative;
}

.dropPlaceholder {
  $padding: 8px;

  padding: $padding;
  background: white;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  user-select: none;

  z-index: 15;

  &__content {
    text-align: center;
    position: relative;
    background: #FAFBFC;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #8E8E93;
    font-size: 14px;
    font-weight: 400;
    cursor: copy;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      background: linear-gradient(90deg, #4778EE 70%, transparent 30%),
      linear-gradient(90deg, #4778EE 70%, transparent 30%),
      linear-gradient(0deg, #4778EE 70%, transparent 30%),
      linear-gradient(0deg, #4778EE 70%, transparent 30%);
      background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      background-size: 8px 1px, 8px 1px, 1px 8px, 1px 8px;
      background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;

      animation: dash 20s linear infinite;
    }
  }
}


@keyframes dash {
  to {
    background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
  }
}
