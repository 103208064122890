.paper {
  width: 460px;
  max-width: 100%;
  overflow-x: hidden;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 24px;
}

.image {
  max-width: 100%;
  height: 200px;
  margin: 24px auto 16px;
}

.title {
  padding-top: 0px !important;
  padding-bottom: 16px !important;
}

.content {
  padding: 0 24px;

  & > *:last-child {
    padding: 32px 0 40px
  }
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #272A36;
  margin-bottom: 20px;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
}
