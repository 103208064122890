.root {
  width: 100%;
}

.scopeContainer {
  position: relative;

  &Collapsed {
    &:after {
      content: '';
      z-index: 30;
      position: absolute;
      left: 0;
      top: -40px;
      width: 100%;
      height: 200px;

      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1));
    }
  }
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 60px;
}


.expand {
  padding: 16px 24px;
  margin: 0 auto;
}

@media(max-width: 1000px) {
  .scopeContainerCollapsed:after {
    top: -40px;
    height: 160px;
  }
}
