@import "@roolz/sdk/assets/mixins.scss";

.button {
  --text-color: white;
  --bg: #4778EE;

  cursor: pointer;
  border: 0;

  font-size: 16px;
  font-weight: 500;

  background: var(--bg);
  color: var(--text-color);
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  //transition: 0.3s background, 0.3s border;

  &Hidden {
    visibility: hidden;
  }

  &Current {
    background: #F8F9FC;
    color: #272A36;
    border: 1px solid #E5E6EE;
    pointer-events: none;
    cursor: default;
    font-weight: 700;
  }

  &White {
    --text-color: #4778EE;
    --bg: white;

    @include pseudo-border(#EBECF2);
    //border: 1px solid ;
  }

  &Light {
    --text-color: #4778EE;
    --bg: #F8F9FC;

    border-top: 1px solid #EBECF2;
  }

  &Transparent {
    --text-color: #4778EE;
    --bg: transparent;

    border: 1px solid #EBECF2;
  }
}

.current {
  cursor: default;

  background: #F8F9FC;
  color: #272A36;
  border: 1px solid #E5E6EE;
  font-weight: 700;

  pointer-events: none;
}

.white {
  --text-color: #4778EE;
  --bg: white;

  @include pseudo-border(#EBECF2);
  //border: 1px solid ;
}

.light {
  --text-color: #4778EE;
  --bg: #F8F9FC;

  border-top: 1px solid #EBECF2;
}

.transparent {
  --text-color: #4778EE;
  --bg: transparent;

  border: 1px solid #EBECF2;
}

.popular {
  --bg: linear-gradient(128.49deg, #3378FF 0%, #9442FE 100%);
}

.valuable {
  --text-color: #272A36;
  --bg: linear-gradient(126.95deg, #FDE7EA 2.31%, #FDEFDA 100%);

  @include pseudo-border(#FFD6C6);
}
