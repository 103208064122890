.root {
  text-align: center;
  padding: 60px 0 10px;
}

.title {
  font-size: 42px;
  font-weight: 700;
  text-align: center;
  color: #272A36;
  line-height: 49px;

  strong {
    color: #4778EE;
  }
}

.subtitle {
  margin-top: 12px;

  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #272A36;
  line-height: 28px;

  strong {
    color: #4778EE;
  }
}

.chip {
  display: inline-block;
  z-index: 1;
  position: relative;
  margin-top: 24px;

  padding: 8px 12px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #272A36;

  &__image {
    width: 32px;

    z-index: -2;
    position: absolute;
    top: -3px;
    right: 0px;
    transform: translate(50%, -50%);
  }


  strong {
    color: #4778EE;
  }

  &:before {
    content: '';
    position: absolute;

    z-index: -1;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    border: 1px solid #4778EE;
    border-radius: 10px;

    mask-image: linear-gradient(to bottom, rgba(1,1,1,1), rgba(1,1,1,0));
  }
}

@media(max-width: 480px) {
  .title {
    font-size: 28px;
    line-height: 32px;
  }

  .subtitle {
    font-size: 20px;
    line-height: 23px;
  }
}
