.alert {
  border-radius: 8px;
  background: linear-gradient(0deg, #F0F4FE 0%, #F0F4FE 100%), #4778EE;
  padding: 16px;
  display: flex;
}

.icon {
  flex-shrink: 0;

  margin-right: 8px;
  position: relative;
  top: 2px;
}

.content {
  color: #272A36;
  font-size: 14px;
  font-weight: 400;
}
