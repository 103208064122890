.root {
  border: 1px solid #E5E6EE;
  border-radius: 8px;

  padding: 16px;
}

.header {
  display: flex;

  &__content {
    margin-left: 12px;

    &>:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.name {
  color: #272A36;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
}

.price {
  font-size: 14px;

  &__amount {
    color: #272A36;
    font-weight: 500;
  }

  &__type {
    color: #8E8E93;
    font-weight: 400;
  }
}

.members {
  margin-top: 12px;

  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  color: #8E8E93;

  &__count {
    color: #272A36;
  }
}

.icon {
  width: 40px;
  height: 40px;
}
