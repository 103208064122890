.root {
  max-height: 100%;
  overflow-y: scroll;
  flex-grow: 1;

  width: 100%;

  &>* {
    flex-shrink: 0;
  }
}

.content {
  min-height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.icon {
  min-height: 100px;

  &>svg {
    height: 100%;
  }
}

.label {
  margin-top: 32px;
  color: #272A36;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
