.paper {
  width: max(400px, 100vw);
  border-radius: 12px !important;
}

.root {
  text-align: center;
  color: #272A36
}

.content {
  padding: 16px 24px 0;
}

.icon {
  margin: 40px auto 32px;
  width: 180px;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
}

.title {
  font-size: 24px;
  font-weight: 400;
  line-height: 28.13px;
  text-align: center;
  color: #272A36;
}

.description {
  margin-top: 16px;

  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  color: #272A36;
}

.seats {
  margin-top: 28px;

  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  color: #8E8E93;

  display: inline-flex;
  align-items: center;
  gap: 4px;

  &__icon {
    position: relative;
    bottom: 2px;
  }
}

.accept {
  //margin-top: 14px;

  cursor: pointer;

  border-top-left-radius: 0;
  border-top-right-radius: 0;

  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 64px;

  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;

  border: 0;
  outline: none;
}

.calc {
  padding: 0 0 16px;
}
