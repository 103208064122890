$color: #272A36;

.content {
  padding: 8px;
  color: $color;
  word-break: break-word;
  //display: flex;
  //justify-content: space-between;

  a {
    text-decoration: none;
    font-weight: 400;
    transition: 0.2s all;
    color: $color;

    &:hover {
      color: #4778EE;
    }
  }
}

.attributes {
  //flex-shrink: 0;
  margin-left: 6px;

  float: right;
  margin-top: auto;

  position: relative;
  top: 3px;
}
