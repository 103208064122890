.root {
  background: rgb(250, 251, 252);
  padding: 60px 20px;
  display: flex;
  justify-content: center;
}

.title {
  margin-bottom: 32px;

  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
}

.container {
  width: 100%;
  max-width: 872px;
  margin: auto;
}

.list {
  &>* {
    margin-bottom: 24px;
  }
}

.rootDark {
  background: #272A36;

  .title {
    color: #FFF;
  }
}
