.root {
  border-bottom: 0 !important;
}

.price {
  padding: 12px 10px;

  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  color: #6C7090;

  &__quantity {
    font-weight: 700;
    color: #272A36;
  }

  &__type {

  }
}

.monthlyPricePanel {
  margin-top: 12px;
}
