@import "@roolz/sdk/assets/mixins.scss";

.root {
  width: 100%;
}

.content {
  width: 100%;
  display: flex;
  align-items: stretch;
  position: relative;

  padding-top: 40px;

  --item-width: calc(100% / var(--count));

  &:after {
    transition: .3s all;

    content: '';
    position: absolute;

    width: var(--item-width);
    height: 2px;

    background: #4778EE;

    bottom: 0px;
    left: calc(var(--active-i) * var(--item-width));
  }
}

.item {
  cursor: pointer;

  min-width: 0;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: var(--item-width);

  text-align: center;
  padding: 12px 6px;

  position: relative;
  outline: none;
  background: white;

  border: 1px solid #EBECF2;
  border-bottom: none;

  &:not(:last-child) {
    border-right: none;
  }

  &__text {
    display: block;

    color: #6C7090;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;

    @include ellipsis;
  }

  &Active {
    transition: 0.3s all;
    cursor: default;

    color: #4778EE;
  }
}

.label {
  position: absolute;
  max-width: 100%;

  top: 0;
  left: 50%;
  z-index: 1;

  transform: translate(-50%, -100%);
}

.lighterLeft, .lighterRight {
  display: none;
}

// Slider mode
@media(max-width: 480px) {
  .root {
    width: 100%;
    position: relative;

  }

  .content {
    --item-width: max(30%, 150px);

    overflow-x: scroll;
    overflow-y: visible;

    @include invisible-scroll;

    //padding-top: 40px;
  }

  .item {
    flex-shrink: 0;
  }

  .lighterLeft, .lighterRight {
    content: '';
    position: absolute;
    height: calc(100% + 2px);
    top: -1px;
    width: 25%;
    z-index: 2;
    pointer-events: none;

    display: block;
  }

  .lighterLeft {
    left: 0;
    background: linear-gradient(to right, white, transparent);

  }
  .lighterRight {
    right: 0;
    background: linear-gradient(to left, white, transparent);

  }
}
