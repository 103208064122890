.root {
  display: flex;
  width: 100%;
  max-width: 100%;

  flex-grow: 1;
  min-width: 1px;
  overflow: hidden;
}

//.content {
//  padding: 8px 0;
//  display: flex;
//  flex-grow: 1;
//  max-width: 100%;
//  min-width: 1px;
//  overflow: hidden;
//  //text-overflow: ellipsis;
//
//  &>* {
//    flex-shrink: 0;
//  }
//}

.content {
  margin: 4px 0;
}

.icon {
  width: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  margin: 4px 0;
}

.close {
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transform: rotate(0deg);
  transition: 0.3s transform;

  &:hover {
    transform: rotate(180deg);
  }
}
