.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(1, 1, 1, 0.37);

  &NonInteractive {
    pointer-events: none;
  }
}
