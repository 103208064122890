@import '@roolz/sdk/assets/mixins.scss';

.preview {
  border-left: 2px solid #4778EE;

  padding: 4px 4px 0;
  flex-grow: 1;
  min-width: 1px;
  cursor: pointer;
  user-select: none;

  //@include ellipsis;
}

.title {
  color: #4778EE;
  font-size: 12px;
  font-weight: 500;

  min-width: 1px;
  @include ellipsis;
}

.messageContent {
  color: #272A36;
  max-width: 100%;

  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
  min-width: 1px;

  @include ellipsis;
}
