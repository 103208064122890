@import '@roolz/sdk/assets/mixins.scss';

.root {
  max-width: 100%;

  background: rgb(247, 247, 247);
  padding: 4px;
  border-radius: 37px;
  //display: inline-block;
  display: flex;
  flex-wrap: nowrap;

  position: relative;
  //z-index: 0;

  //height: 36px;

  //overflow-x: scroll;
  //@include invisible-scroll;
}

.item {
  --bubble-color: white;

  position: relative;

  padding: 8px 35px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  border-radius: 37px;
  z-index: 1;

  color: #8E8E93;

  border: 0;
  outline: none;
  background: transparent;
  cursor: pointer;

  transition: .3s color;


  //flex-grow: 1;
  //flex-shrink: 0;

  @include ellipsis;

  &Active {
    color: #4778EE;
  }

  @media(max-width: 500px) {
    flex-basis: 50%;

  }
}


.bubble {
  position: absolute;

  z-index: 0;

  content: '';
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  background: var(--color);
  border-radius: inherit;
  box-shadow: 0px 2px 4px 0px #00000014, 0px 0px 6px 0px #00000005;

  height: 32px;
  //left: 0;
  //top: 0;
  //right: 0;
  //bottom: 0;
  //width: 100%;
  //height: 100%;

  transition: 0.3s background, 0.3s color, 0.3s box-shadow;
}
