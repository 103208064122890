@import '@/assets/scss/mixins.scss';

.paper {
  width: min(400px, 100vw);
  border-radius: 12px !important;
}

.content {
  padding: 24px;
  color: #272A36;
}

.title {
  font-size: 24px;
  font-weight: 400;
  line-height: 28.13px;
}

.description {
  margin-top: 16px;

  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
}

.presetAmounts {
  margin-top: 16px;

  display: flex;
  justify-content: space-between;
  gap: 16px;
  height: 40px;

  &>* {
    flex-grow: 1;
  }

  &__item {
    border: 1px solid #E5E6EE;
    border-radius: 4px;
    background: #FAFBFC;

    color: #4778EE;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    transition: 0.3s all;

    &Active {
      background: #4778EE;
      color: white;
    }
  }
}

.field {
  height: 40px;

  &__wrapper {
    margin-top: 16px;
  }

  &__input {
    text-align: center !important;
  }
}

.error {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: center;
  color: #E23834;

  margin-top: 28px;
}

.actions {
  margin-top: 4px;

  display: flex;
  justify-content: space-between;
  gap: 16px;

  &>* {
    flex: 1 0 0;
  }
}

.precalc {
  min-height: 40px;

  &>:last-child {
    margin-bottom: 20px;
  }
}
