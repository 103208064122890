.main {
  height: 48px;
  width: 100%;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;
  border-top: 1px solid #E5E6EE;
  background: #FAFBFC;
  cursor: pointer;

  color: #4778EE;
  font-size: 14px;
  font-weight: 500;
}
