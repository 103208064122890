@import '@roolz/sdk/assets/mixins.scss';

$height: 44px;

.root {
  outline: none;
  border: 0;
  cursor: pointer;

  background-size: 100% 100%;

  width: 204px;
  height: $height;

  display: flex;
  align-items: center;

  border-radius: calc($height / 2);

  &:hover {
    .rightIcon {
      background: linear-gradient(90deg, #99A8FF 0%, #FFA3CE 100%);

      &__arrow {
        color: white;
        transform: rotate(45deg);
      }
    }
  }
}

.leftIcon {
  flex-shrink: 0;

  $gap: 2px;

  margin-left: $gap;

  height: calc($height - $gap * 2);
  width: calc($height - $gap * 2);

  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: white;

  box-shadow: 0px 2px 4px 0px #00000014,
    0px 0px 6px 0px #00000005;

  transition: .6s all;

  &>* {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.content {
  flex-grow: 1;

  min-width: 0;

  text-align: left;
  color: white;

  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;

  @include ellipsis;

  &__highlight {
    max-width: 100%;
    min-width: 0;
    //font-size: 14px;
    font-weight: 600;

    text-transform: uppercase;

    @include ellipsis;
  }

  padding: 0 10px;
}

.rightIcon {
  flex-shrink: 0;

  background: white;

  width: 24px;
  height: 24px;
  border-radius: 100%;

  margin-right: 10px;

  box-shadow: 0px 2px 4px 0px #00000014,
    0px 0px 6px 0px #00000005;

  border: 1px solid white;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: .3s all;

  &__arrow {
    transition: .3s transform;

    transform: rotate(90deg);
    color: #F2A4D4;
  }
}
