@import '@/assets/scss/mixins.scss';

.paper {
  width: 424px;
  max-width: 100vw;
  border-radius: 10px !important;

  height: min(100vh, 920px);
}

.header {
  padding: 16px;
  width: 100%;

  &>* {
    width: 100%;
  }
}

.root {
  //width: 100%;
  height: min(100vh, 920px);

  //display: flex;
  //flex-direction: column;
  //align-items: stretch;
  //justify-content: center;
  //min-height: 1px;
}

.header {
  flex-shrink: 0;
  position: relative;
}

.content {
  min-height: 1px;
  max-height: 100%;
  //overflow-y: scroll;
  flex-grow: 1;
  flex-shrink: 0;
}

.chat__content {
  color: #8E8E93;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @include ellipsis;
}

.result {
  height: 100%;
  overflow-y: scroll;
}

.placeholder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0;

  &__title {
    margin-top: 32px;
    color: #272A36;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }

  &__description {
    margin-top: 16px;

    color: #272A36;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.body {
  border-top: 1px solid #D9D9D9;
}

.title {
  color: #272A36;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 16px;
}

.close {
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;

  position: absolute;
  right: 0;
  top: 0;

  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    transform: rotate(00deg);
    transition: 0.3s all;
  }

  &:hover {
    & .close__icon {
      transform: rotate(180deg);
    }
  }
}

.cleanSearch {
  cursor: pointer;
  border: 0;
  background: transparent;
  height: 100% !important;
  max-height: unset !important;

  padding: 0 5px;
  padding-right: 15px;

  transform: translateX(10px);
  position: absolute;
  top: -1px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}
