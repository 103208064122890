.newMessagesCount {
  $height: 18px;

  flex-shrink: 0;

  margin-left: 4px;
  min-width: $height;
  height: $height;
  padding: 0 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #4778EE;

  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #FFFFFF;
  border-radius: calc(#{$height} / 2);

  transition: 0.3s all;

  &__muted {
    background: #8E8E93;
  }

  &__withBorder {
    border: 1px solid white;
  }
}
