.withBadge {
  position: relative;
  color: #272A36;

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    left: 8px;
    top: -4px;
    background: #fff;
    border-radius: 50%;
  }

  &:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    left: 9px;
    top: -3px;
    background: #E23834;
    border-radius: 50%;
  }
}
