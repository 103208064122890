.label {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #8E8E93;
  transition: all .2s;
  user-select: none;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;

    &.error {
      color: #E23834;
    }

    &.checked {
      &.default {
        color: #4778EE;
      }

      &.light {
        color: #fff;
      }
    }

    &.disabled:not(.light) {
      color: #A8A8A9 !important;
    }
  }

  .content {
    display: inline-flex;
    align-items: center;

    &.checked {
      &.default {
        color: #272A36;
      }

      &.light {
        color: #fff;
      }
    }

    &.disabled:not(.light) {
      color: #A8A8A9 !important;
    }
  }

  .input {
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;
  }
}






