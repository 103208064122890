.root {
  line-height: 0;
  color: #8E8E93;
  position: relative;
  bottom: 2px;
}

.icon {
  margin-right: -1px;
  position: relative;
  top: 3px;
}
