@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin applyToChildrenExceptLast($property, $value) {
  & > *:not(:last-child) {
    #{$property}: #{$value}
  }
}

@mixin invisible-scroll {
  overflow: -moz-scrollbars-none;
  overflow: -moz-hidden-unscrollable;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    -ms-overflow-style: none;
  }
}
