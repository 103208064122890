.valuableLabel {
  font-size: 10px;
  font-weight: 700;
  line-height: 11.72px;
  color: #272A36;

  //position: absolute;
  //top: 0;
  //left: 50%;
  //
  //transform: translate(-50%, -100%);

  background: linear-gradient(126.95deg, #FDE7EA 2.31%, #FDEFDA 100%);
  border-radius: 8px 8px 0 0;
  padding: 6px 10px 4px 10px;

  &__img {
    position: absolute;
    right: 2px;
    top: -2px;

    width: 20px;

    transform: translate(50%, -50%);
  }
}
