.paper {
  border-radius: 28px !important;
  width: min(400px, 100vw);
}

.title {
  font-size: 24px;
  font-weight: 400;
  line-height: 28.13px;
  color: rgb(39, 42, 54);
}

.header {
  position: relative;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.content {
  padding: 0 24px 24px;
}

.newPlan {
  margin-bottom: 16px;
}

.confirm {
  margin-top: 24px;
  width: 100%;
  padding: 12px;
}
