.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  display: block;
  width: 100%;

  color: #8E8E93;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  padding: 6px;
  text-align: center;

  transition: 0.2s color;

  &:hover {
    color: #3667DD;
  }
}
