@import '@roolz/sdk/assets/mixins.scss';
@import '@/assets/scss/config.module.scss';

.invites {
  max-height: 200px;
  overflow-y: auto;

  padding: 0 16px 16px;
  margin-top: 16px;
  @include applyToChildrenExceptLast(margin-bottom, 16px);

  &.showScroll {
    overflow-y: scroll;

    box-shadow: 0px -16px 20px -4px rgba(34, 60, 80, 0.05) inset;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.invite {
  border-radius: 12px;
  padding: 16px;
  border: 1px solid #E5E6EE;

  &__company {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    &Warning {
      display: none;
    }
    &Avatar {
      width: 40px;
      height: 40px;
      margin-right: 12px;
      flex-shrink: 0;
    }
    &Title {
      font-family: Roboto, serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0;
      color: #8E8E93;
      margin-bottom: 4px;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
    &Name {
      font-family: Roboto, serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    &Item {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(50% - 8px);
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        pointer-events: auto;
      }

      &.Decline {
        border: 1px solid #E23834;
        color: #E23834;
      }
      &.Accept {
        color: #3EBF71;
        border: 1px solid #3EBF71;
      }
    }
  }
  &Collapsed {
    border-radius: 12px;
    display: flex;
    justify-content: center;
    border: none;
    margin-top: 0;
    padding: 10px;
    position: relative;
    .invite__company {
      margin-bottom: 12px;
    }

    .invite__companyAvatar {
      margin-right: 0;
    }

    .invite__buttons {
      display: none;
    }

    .invite__companyRight {
      display: none;
    }
    .invite__companyWarning {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #E59F42;
      border: 2px solid #fff;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0px;
      width: 18px;
      height: 18px;
      font-size: 9px;
      text-align: center;
      font-weight: 900;
    }
  }
}

@media(max-width: $mobile-sidebar-breakpoint-width) {
  .invite {
    margin-top: 3px;
    margin-bottom: 16px;
  }
}
