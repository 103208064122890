.dialog {
  &__icon {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 12px;
  }
  &__title {
    font-family: Roboto, serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    color: #272A36;
    margin-bottom: 16px;
    min-width: 300px;
    text-align: center;
  }

  &__notify {
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    color: #272A36;
    margin-bottom: 24px;
    text-align: center;
  }

  &__company {
    display: flex;
    align-items: center;
    &Text {
      font-family: Roboto, serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0;
      text-align: left;
      color: #272A36;
      margin-left: 16px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      max-width: 200px;
    }
    &Avatar {
      flex-grow: 1;
    }
  }

  &__action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 24px;
  }
}
