.root {
  padding: 12px 0;

  text-align: center;
  //user-select: none;
  cursor: default;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8E8E93;
}

.profile {
  color: #4778EE;
  border: 0;
  display: inline;
  background: transparent;
  margin: 0 2px 0 3px;
  cursor: pointer;
}
