.root {
  cursor: pointer;
  height: 48px;
  display: inline-flex;
  align-items: center;
  padding-left: 12px;
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  background: #4778EE;
  box-shadow: 0px 92px 37px rgba(71, 120, 238, 0.01), 0px 52px 31px rgba(71, 120, 238, 0.05), 0px 23px 23px rgba(71, 120, 238, 0.09), 0px 6px 13px rgba(71, 120, 238, 0.1), 0px 0px 0px rgba(71, 120, 238, 0.1);
  border-radius: 8px;
  animation: 0.3s appear ease alternate;
  z-index: 2000;
}

.icon {
  line-height: 1;
  display: inline-flex;
}

.content {
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
}

.button {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  padding: 8px 16px;
  height: 100%;
  display: inline-flex;
  align-items: center;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media(max-width: 600px) {
  .root {
    width: 300px;
    max-width: 95vw;
  }
}
