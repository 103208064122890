.root {
  padding-right: 10px;
  padding-left: 12px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background: transparent;
  border: 0;

  &Active {

  }
}

.menu__content {
  z-index: 99;
  background: white;
}

.fileIcon {
  width: 18px;
  transform: rotate(-45deg);
}
