.button {
  height: 40px;
  display: inline-flex;
  align-items: center;
  border: 0;
  background: transparent;
  cursor: pointer;

  padding-right: 15px;
  padding-left: 10px;

  &Disabled {
    cursor: not-allowed;

    .icon {
      opacity: 0.54;
    }
  }
}

.icon {
  transition: 0.2s all;
}
