.root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: inherit;
  color: #272A36;
  border: 0;
  background: transparent;
  outline: none;
  cursor: pointer;

  .icon {
    margin-left: 4px;
    color: #8E8E93;
  }
}

.title {
  display: block;
  width: 250px;
  max-width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
