$sidebar-width: 334px;
$sidebar-collapsed-width: 100px;

$header-height: 62px;


// max width on which should be visible mobile sidebar
$mobile-sidebar-breakpoint-width: 700px;

:export {
  sidebarWidth: $sidebar-width;
  sidebarWidthCollapsed: $sidebar-collapsed-width;

  mobileSidebarBreakpointWidth: $mobile-sidebar-breakpoint-width;
}
