.paper {
  width: min(424px, 99vw);
  border-radius: 12px !important;
}

.wrapper {
  padding: 24px;
}

.close {
  position: absolute;
  right: 0;
  top: 0;

  width: 60px !important;
  height: 60px !important;
}

.header {

  padding-right: 50px;
  display: flex;

  &>svg {
    flex-shrink: 0;
  }

  &__text {
    flex-grow: 1;

    margin-left: 16px;
    color: #272A36;
    font-size: 24px;
    font-weight: 400;
  }
}

.content {
  margin-top: 24px;
}

.description {
  margin-bottom: 16px;
  color: #272A36;
  font-size: 14px;
  font-weight: 400;
}

.step {
  margin-bottom: 20px;

  &__image {
    margin-top: 8px;
    width: 100%;
  }

  &__number {
    color: #4778EE;
    font-size: 14px;
    font-weight: 700;
  }

  &__text {
    color: #272A36;
    font-size: 14px;
    font-weight: 400;
  }
}


