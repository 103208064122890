.root {
  width: min(100%, 450px);
  border-radius: 24px !important;
}

.content {
  padding: 0 24px 20px ;
  overflow-y: hidden;

  min-height: 200px;
}

.submit {
  margin-top: 20px;
  width: 100%;
}

.close {
  position: absolute;
  top: 10px;
  right: 5px;

  z-index: 10;
}
