.root {
  padding: 16px 10px;
  cursor: pointer;

  border: none;
  border-top: 1px solid #E5E6EE;
  background: #FAFBFC;

  color: #272A36;
  font-size: 14px;
  font-weight: 500;

  width: 100%;
}
