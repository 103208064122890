.root {
  position: relative;
  z-index: 1201;
  pointer-events: none;

  &Mobile {
    z-index: auto;
    //height: 100% !important;
  }

  &>* {
    pointer-events: all;
  }
}
