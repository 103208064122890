@import '@/assets/scss/mixins.scss';

.chat {
  padding-left: 30px;
  display: flex;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;
  height: 55px;

  background: white;
  border: 0;
  transition: 0.3s background;


  &Pined {
    background: #F7F7F7;
  }

  &Active {
    transition: 0.2s background;
    background: #E8F2FD !important;
  }

  &:not(&Active):hover {
    background: #F7F7F7;
  }
}

.avatar {
  flex-shrink: 0;
  position: relative;
  width: 40px;
}


.content {
  margin-left: 12px;
  min-width: 1px;
  flex-grow: 1;
  height: 100%;
  padding: 0 12px 0 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  border-bottom: 1px solid #E2E2E2;
  opacity: 1;

  //&>* {
  //  flex-shrink: 0;
  //}

  &__header {
    display: flex;
    align-items: center;
  }

  &__footer {
    margin-top: 4px;

    width: 100%;
    display: flex;
    align-items: center;
  }

  &__footer:empty {
    display: none;
  }

  &Hidden {
    transition: 0.3s all;
    opacity: 0;
    width: 0;
  }
}

.spacer {
  flex-grow: 1;
  //width: 100%;
}

.name {
  &__container {
    min-width: 1px;
    max-width: 100%;
  }

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #272A36;

  @include ellipsis;
}


.icon {
  width: 18px;
  height: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
