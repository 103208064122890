.chipArrow {
  position: absolute;
  right: -8px;
  bottom: 45%;

  transform: translate(100%, 5px);
}

.chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #4778EE;
  border-radius: 11px;

  height: 25px;
  padding: 0 8px;

  font-size: 14px;
  font-weight: 700;
  color: #4778EE;
  background: white;

  position: absolute;
  z-index: 1;

  right: 0;
  top: -7px;

  transform-origin: top left;
  transform: translate(60%, -100%) rotate(3deg) scale(0.8);
}

@media (max-width: 700px) {
  .chipArrow {
    display: none;
  }

  .chip {
    transform: translate(0%, -80%) rotate(-1.5deg);
  }
}

.rootCompact {
  .chipArrow {
    transform: translate(100%, -5px);
  }

  .chip {
    right: -8px;
    transform: translate(100%, -30%) rotate(3deg) scale(0.8);
  }
}
