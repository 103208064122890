.paper {
  border-radius: 0 !important;
  width: 100% !important;
  max-width: unset !important;
  margin: 0 !important;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
}

.root {
  position: relative;
  width: 100%;
}

.container {
  padding: 20px;
}

.itemsContainer {
  --item-width: 310px;
  --items-per-row: 4;
  --gap-y: 34px;
  --gap-x: 18px;

  --width: calc(var(--item-width) * var(--items-per-row) + var(--gap-x) * (var(--items-per-row) - 1));

  width: var(--width);

  max-width: 100%;
}


.items {
  margin-top: 56px;
  margin-left: auto;
  margin-right: auto;

  gap: var(--gap-y) var(--gap-x);

  &>* {
    width: var(--item-width);
  }
}

.paymentSystems {
  padding: 0 10px;
  margin: 30px 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.vatLabel {
  max-width: min(1320px, 100%);
  margin: 24px auto 0;
}

@media(max-width: 1400px) {
  .items {
    --item-width: 306px;
    --items-per-row: 2;
    --gap-x: 46px;
    --gap-y: 46px;

    &>:last-child {
      width: var(--item-width);
    }
  }
}

@media(max-width: 1100px) {
  .items {
    --item-width: 356px;
    --items-per-row: 2;
    --gap-x: 35px;
    --gap-y: 35px;
  }
}

@media(max-width: 834px) {
  .items {
    --item-width: 100%;
    --items-per-row: 1;
    --gap-x: 35px;
    --gap-y: 35px;
  }
}
