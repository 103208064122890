
.countryTag {
  background: #F7F7F7;
  border-radius: 4px;
  display: inline-flex;
  padding: 2px 4px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  &__flag {
    width: 16px;
    height: 12px;
  }

  &__label {
    margin-left: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #8E8E93;
  }
}
