@import '@/assets/scss/mixins.scss';

.root {
  &:hover {
    background: white !important;
  }
}

.title {
  color: #8E8E93;
  font-size: 12px;
  font-style: normal;
  line-height: 14px;
  font-weight: 400;

  @include ellipsis;
}

.content {
  color: #4778EE;
  font-size: 14px;
  font-weight: 500;
  @include ellipsis;
}

.avatar {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
