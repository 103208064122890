@import '@/assets/scss/mixins.scss';

.button {
  padding: 11px 16px;
  border-radius: 8px;
  border: 1px solid transparent;
  background: transparent;
  cursor: pointer;
  user-select: none;
  transition: 0.25s color, 0.25s background, 0.25s border, 0.25s box-shadow;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @include ellipsis;

  &Icon {
    padding: 0;
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:disabled, &:hover, &:active {
    box-shadow: none !important;
  }

  &.size {
    &Medium {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    &Small {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &.variantContained {
    background: var(--bg);
    color: var(--text);
  }

  &.variantSecondary {
    background: var(--bg);
    color: var(--text);
  }

  &.variantText {
    background: var(--bg);
    color: var(--text);
  }

  &.variantOutlined {
    background: var(--bg);
    color: var(--text);
    border: 1px solid var(--text);
  }

  &.colorPrimary {
    &.variantContained {
      --bg: #4778EE;
      --text: white;
      box-shadow: 0px 49px 20px rgba(71, 120, 238, 0.01), 0px 28px 17px rgba(71, 120, 238, 0.05), 0px 12px 12px rgba(71, 120, 238, 0.09), 0px 3px 7px rgba(71, 120, 238, 0.1), 0px 0px 0px rgba(71, 120, 238, 0.1);

      &:disabled {
        --bg: #a4c2fc !important;
      }

      &:hover {
        --bg: #3667DD;
      }

      &:active {
        --bg: #2C5BCC;
      }
    }

    &.variantSecondary {
      --bg: #F7F7F7;
      --text: #272A36;

      &:disabled {
        --bg: #EDEDED !important;
        --text: #8E8E93 !important;
      }

      &:hover {
        --bg: #EFEFEF;
      }

      &:active {
        --bg: #EAEAEA;
      }
    }

    &.variantText {
      --bg: transparent;
      --text: #4778EE;

      &:disabled {
        --text: rgba(71, 120, 238, 0.46) !important;
      }

      &:hover {
        --text: #3667DD;
      }

      &:active {
        --text: #2C5BCC;
      }
    }

    &.variantOutlined {
      --bg: transparent;
      --text: #4778EE;

      &:disabled {
        --text: rgba(71, 120, 238, 0.46) !important;
      }

      &:hover {
        --text: #3667DD;
      }

      &:active {
        --text: #2C5BCC;
      }
    }
  }

  &.colorDanger {
    &.variantContained {
      --bg: #E23834;
      --text: white;

      &:disabled {
        --bg: #ff9ea0 !important;
      }

      &:hover {
        --bg: #DB3632;
      }

      &:active {
        --bg: #D13430;
      }
    }

    &.variantSecondary {
      --bg: #F7F7F7;
      --text: #E23834;

      &:disabled {
        --bg: #EDEDED !important;
        --text: #F2A3A2 !important;
      }

      &:hover {
        --bg: #EFEFEF;
        --text: #DB3632;
      }

      &:active {
        --bg: #EAEAEA;
        --text: #D13430;
      }
    }

    &.variantText {
      --bg: transparent;
      --text: #E23834;

      &:disabled {
        --text: #F2A3A2 !important;
      }

      &:hover {
        --text: #DB3632;
      }

      &:active {
        --text: #D13430;
      }
    }

    &.variantOutlined {
      --bg: transparent;
      --text: #E23834;

      &:disabled {
        --text: #F2A3A2 !important;
      }

      &:hover {
        --text: #DB3632;
      }

      &:active {
        --text: #D13430;
      }
    }
  }


  &.colorMuted {
    &.variantText {
      --bg: transparent;
      --text: #848488;

      &:disabled {
        --text: #cbcbcd !important;
      }

      &:hover {
        --text: #848488;
      }

      &:active {
        --text: #7E7E83;
      }
    }
  }

  //&.colorMuted {
  //  &.variantText {
  //    --bg: transparent;
  //    --text: #E23834;
  //
  //    &:disabled {
  //      --text: #F2A3A2 !important;
  //    }
  //
  //    &:hover {
  //      --text: #DB3632;
  //    }
  //
  //    &:active {
  //      --text: #D13430;
  //    }
  //  }
  //}
}
