@import '@/assets/scss/config.module.scss';

.chat {
  background: #FFFFFF;
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  border-radius: 4px 4px 0 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  z-index: 1000;
  max-width: max(100%, 100vw);
  max-height: max(100%, 100vh);

  transition: 0.3s all;

  &Mobile {
    width: 100% !important;
    height: 100% !important;
  }

  &NoTransition {
    transition: none;
  }

  &Expanded {
    box-shadow: none;

    width: calc(100% - var(--sidebar-width)) !important;
    height: calc(100% - $header-height) !important;
  }
}

.header {
  flex-shrink: 0;
  cursor: grab;
  user-select: none;

  &Moving {
    cursor: grabbing;
  }
  //user-select: none;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 1px;
  flex-grow: 1;
}

.messages {
  min-height: 1px;
  flex-grow: 1;
  flex-shrink: 1;
}

.bottomAction {
  flex-shrink: 0;
}
//
//@media (screen or max-width: 500px) {
//  .chat {
//    width: 100% !important;
//    height: 100% !important;
//  }
//}
