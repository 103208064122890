.popper {
  z-index: 50;
}

.tooltip {
  background: #E23834 !important;
  border-radius: 100px !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  color: #FFFFFF !important;
  text-align: center !important;
  max-width: 170px !important;
  padding: 4px 12px !important;

  &Hidden {
    display: none;
  }
}


.wrapper {
  position: relative;

  &FullWidth {
    width: 100%;
  }
}

.icon {
  z-index: 1;
}
