.paper {
  width: 330px;
}

.root {
  padding: 24px;
  color: #272A36;
  position: relative;
}

.header {
  //position: relative;
  padding-right: 50px;
}

.closeButton {
  position: absolute;
  right: 5px;
  top: 7px;
  //transform: translate(30%, -50%);
}

.title {
  font-size: 24px;
  font-weight: 400;
  line-height: 28.13px;
}

.description {
  margin-top: 16px;

  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
}

.actions {
  margin-top: 24px;

  display: flex;
  justify-content: flex-end;
}


.formGroup {
  margin-top: 16px;
}

.type__item {
  padding-left: 15px;
  padding-right: 15px;

  flex-grow: 1;
}
