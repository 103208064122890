@import '@/assets/scss/mixins.scss';

.root {
  border-bottom: 1px solid #E2E2E2;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}


.content {
  padding: 8px 16px;
  flex-grow: 1;
  display: flex;
  max-width: 100%;
  min-width: 1px;

  &__info {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    min-width: 1px;
  }
}

.actions {
  display: inline-flex;
  align-items: stretch;
}

.avatar {
  margin-right: 16px;
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.name {
  text-decoration: none;

  &__container {
    display: flex;
    align-items: center;
    max-width: 100%;
    min-width: 1px;

    margin-bottom: 2px;
  }

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #272A36;
  max-width: 100%;

  &Dialog {
    transition: 0.3s color;

    &:hover {
      cursor: pointer;
      color: #2c5bcc;
    }
  }


  @include ellipsis;
}

.status {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #8E8E93;
  max-width: 100%;
  min-width: 1px;

  @include ellipsis;
}

.closeButton {
  width: 56px;
  height: 100%;

  background: transparent;
  border: 0;
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  &__icon {
    transition: 0.2s all;
    transform: rotate(0deg);

    path {
      transition: 0.2s all;
      fill: #8E8E93 !important;
    }
  }

  &:hover .closeButton__icon {
    transform: rotate(180deg);

    path {
      fill: black !important;
    }
  }
}

.moreActionsButton {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;

  width: 32px;
  height: 100%;

  background: transparent;
  border: 0;
  cursor: pointer;
}

.expandButton {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;

  width: 32px;
  height: 100%;

  background: transparent;
  border: 0;
  cursor: pointer;

  svg, svg path {
    transition: 0.2s all;
  }

  &:not(.enabled):hover svg {
    animation: 1.5s Expand infinite;

    & path {
      fill: black;
    }
  }
  &.enabled:hover svg {
    animation: 1.5s Unexpand infinite;

    & path {
      fill: black;
    }
  }
}

@keyframes Expand {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes Unexpand {
  0% {
    transform: scale(1.3);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.9);
  }
}
