$height: 36px;

.menuItem {
  outline: none;
  border: 0;

  width: 100%;


  height: $height;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #272A36;
  position: relative;
  border-radius: 12px;
  background: white;
  cursor: pointer;

  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.3s all;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &Disabled {
    cursor: default;
    color: #c8cbde !important;
    pointer-events: none;

    .menuItem__icon path {
      fill: #c8cbde;
      stroke: #c8cbde;
    }
  }

  &__icon, &__icon path {
    transition: 0.2s all;
  }

  &:hover, &Active {
    color: #4778EE;

    .menuItem__icon path {
      fill: #4778EE !important;
    }
  }

  //&:hover {
  //  background: rgba(230, 230, 230, 0.6);
  //}

  &Active {
    background: #F0F4FE;
    color: #4778EE;

    &:before {
      opacity: 1;
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $height;
    width: $height;
    min-width: $height;
    margin-left: 8px;

    path {
      fill: #272A36;
    }
  }

  &__label {
    margin-left: 7px;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

