@import '@/assets/scss/mixins.scss';

$placeholder-color: #8E8E93;

.field {
  line-height: 1;

  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #272A36;
  line-height: 1;

  padding: 12px 12px 12px 2px;

  resize: none;
  border: 0;
  outline: none;

  -webkit-tap-highlight-color: $placeholder-color;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $placeholder-color;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $placeholder-color;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $placeholder-color;
  }

  &:focus, &:active {
    outline: none;
  }

  @include invisible-scroll;
}
