.root {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(214, 186, 115, 0.8);

  width: 100%;
  height: 100%;

  overflow: auto;
  z-index: 9999999;
  padding: 10px;

  font-size: 16px;
}

.item {
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 5px;

  &__date {
    font-size: 0.75em;
    margin-bottom: 4px;
    font-style: italic;
  }

  &__type {
    font-style: normal;
    //color: #F5E0B7;
    font-size: 1em;

    font-weight: 600;
    margin-left: 18px;
  }

  &__body {
    font-size: 0.75em;
  }

  &In {
    //color: white;
    color: white;
    background: black;//#8BBF9F;
  }

  &Out {
    color: black;
    background: white;// #59344F;
  }
}

.clear {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 6px;

  border: 0;
  background: #F5E0B7;
  border-radius: 4px;
  cursor: pointer;
}


.close {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 6px;

  border: 0;
  background: #F5E0B7;
  border-radius: 4px;
  cursor: pointer;
}

