.root {
  border-bottom: 1px solid #E2E2E2;
  display: flex;
  align-items: stretch;
  height: 36px;
  flex-shrink: 0;

  &>* {
    flex-grow: 1;
    flex-basis: 50%;
    flex-shrink: 0;
  }
}

.button {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;
  border: 0;
  background: transparent;

  &__default {
    color: #4778EE;
  }

  &__danger {
    color: #E23834;
  }

  &__icon {
    margin-right: 8px;
  }
}
