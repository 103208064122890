.root {
  padding: 16px 0;

}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  background: #F7F7F7;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8E8E93;

  user-select: none;
}
