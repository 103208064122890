$height: 35px;


.tabs {
  height: $height !important;
  min-height: $height !important;
  padding: 0 11px;
  border-bottom: 1px solid #E2E2E2;
}

.item {
  height: $height !important;
  min-height: auto !important;
  padding: 0 10px !important;

  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  min-width: 60px !important;
  text-transform: none !important;
}

.indicator {
  //bottom: -1px;
  //z-index: 100;
}
