@import "@roolz/sdk/assets/mixins.scss";

.root {
  --price-color: #272A36;
  --border: 1px solid #EBECF2;

  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  //overflow: hidden;

  border: var(--border);

  &Popular {
    --border: 2px solid #4778EE;
    --price-color: #4778EE;
  }

  &Horizontal {
    --price-color: #6C7090;

    --item-width: 100%;

    overflow: hidden;
    flex-direction: row;

    .header {
      width: 310px;

      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      border: 0;

      padding-bottom: 22px;
    }

    .description__blocks {
      flex-direction: row;
      gap: 50px;
    }

    .middleButton {
      margin-top: 16px;
    }
  }
}

.modifierLabel {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}


.header {
  border-radius: 11px 11px 0 0;

  padding: 24px 24px 0;
  background: #F8F9FC;

  border-bottom: 1px solid #EBECF2;

  // may be defined
  max-width: var(--item-width);

  &__description {
    display: inline-flex;
    align-items: center;

    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 14.06px;
    text-align: center;
    color: #6C7090;

    min-height: 4em;

    vertical-align: bottom;

    margin: 8px 0;
  }
}

.title {
  display: flex;
  justify-content: space-between;
}

.name {
  margin-bottom: 16px;

  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  color: #272A36;
}

.icon {
  width: 40px;
}

.price {
  line-height: 1.2;

  position: relative;
  text-align: left;
  color: var(--price-color);

  &Individual {
    font-weight: 400;
  }

  &__currency {
    vertical-align: top;

    left: 0;
    top: 0;
    transform: translate(-100%, 0%);

    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
  }

  &__quantity {
    font-size: 42px;
    font-weight: 500;
    text-align: left;

    &Fractional {
      font-size: 24px;
    }
  }

  &__period {
    text-align: left;
    position: relative;
    bottom: 20px;

    font-size: 14px;
    font-weight: 300;
    line-height: 16.41px;

    color: #6C7090;
  }
}

.description {
  text-align: left;

  padding: 16px 24px 24px;
  flex-grow: 1;

  &__blocks {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__prevPlan {
    font-weight: 500;
    margin-top: 24px;
    text-transform: uppercase;
  }

  &__block {
    margin-top: 24px;

    &__name {
      font-size: 16px;
      font-weight: 500;
      line-height: 18.75px;
      color: #272A36;

      margin-bottom: 16px;
    }
  }
}


.middleButton {
  height: 40px;
  width: 100%;
  border-radius: 8px;
}

.bottomButton {
  height: 40px;
  width: 100%;
  border-radius: 0 0 10px 10px;
}

.list {
  text-align: left;
  list-style: none;

  &__icon {
    margin-right: 8px;
    color: #4778EE;
  }

  li {
    display: flex;

    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    i {
      color: #6C7090;
    }

    //&:before {
    //  content: '>';
    //  height: 100%;
    //
    //  margin-right: 8px;
    //}
  }

}
