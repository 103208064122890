.root {
  background: #F7F7F7;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.group {
  display: flex;
  align-items: center;
  justify-content: center;

  &>:not(:last-child) {
    //margin-right: 6px;
  }
}

.icon {
  cursor: pointer;
  width: 48px;
  height: 48px;
  border: 0;
  background: transparent;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    transition: 0.3s all;
    transform: rotate(0deg);
  }

  &:hover > svg {
    transform: rotate(180deg);
  }
}
