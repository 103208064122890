.root {
  margin: 0 5px;

  flex-shrink: 0;

  &Warning {
    --color: #E59F42;
    --bg-color: #FCF5EC;
  }

  &Error {
    --color: #E23834;
    --bg-color: #FCEBEB;
  }

  height: 44px;

  background: linear-gradient(180deg, var(--bg-color) 0%, #FFFFFF 100%);

  border-radius: 8px;
  padding: 10px;

  display: flex;

  justify-content: center;
  align-items: center;

  &>* {
    flex-shrink: 0;
  }
}

.icon {
  color: var(--color);
}

.content {
  flex-shrink: 1;
  padding: 0 10px;

  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;

  color: var(--color);
}

.description {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;

  display: inline-flex;
  align-items: center;

  margin-top: 2px;

  &__prepend {
    margin-right: 4px;
  }
}

.button {
  border-radius: 4px;
  height: 26px;
  padding: 5px 6px;
}
