.attributes {
  user-select: none;
  display: inline-flex;
  align-items: center;

  &>* {
    margin-left: 2px;
  }
}

.status {
  width: 15px;
  display: inline-block;
  line-height: 0;
}

.editLabel {
  color: #8E8E93;
  font-style: italic;
  margin-right: 3px;
}
