@mixin niceHover {
  overflow: hidden;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;

    transform: translate3d(0px, 100%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;

    background-color: var(--bg-hover);
    border-radius: 500px;
    width: 120%;
    height: 0;
    padding-bottom: 120%;
    bottom: auto;
    left: -10%;
    right: auto;
    transition: 0.5s all;

    z-index: -1;
  }

  &:hover:after {
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);

    //--bg: #3667DD;
  }
}

.button {
  padding: 11px 16px;
  border-radius: 8px;
  border: 1px solid transparent;
  background: transparent;
  cursor: pointer;
  user-select: none;
  transition: 0.25s all !important;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &Icon {
    padding: 0;
  }

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:disabled, &:hover, &:active {
    box-shadow: none !important;
  }

  &.size {
    &Medium {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    &Small {
      padding: 8px 16px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &.variantContained {
    background: var(--bg);
    color: var(--text);
  }

  &.variantSecondary {
    background: var(--bg);
    color: var(--text);
  }

  &.variantText {
    background: var(--bg);
    color: var(--text);
  }

  &.variantOutlined {
    background: var(--bg);
    color: var(--text);
    border: 1px solid var(--text);
  }

  &.colorPrimary {
    &.variantContained {
      @include niceHover;

      --bg-hover: #3667DD;
      --bg: #4778EE;
      --text: white;
      box-shadow: 0px 49px 20px rgba(71, 120, 238, 0.01), 0px 28px 17px rgba(71, 120, 238, 0.05), 0px 12px 12px rgba(71, 120, 238, 0.09), 0px 3px 7px rgba(71, 120, 238, 0.1), 0px 0px 0px rgba(71, 120, 238, 0.1);

      &:disabled {
        --bg: #a4c2fc !important;
      }

      &:active {
        --bg: #2C5BCC;
      }
    }

    &.variantSecondary {
      --bg: #F7F7F7;
      --text: #272A36;

      &:disabled {
        --bg: #EDEDED !important;
        --text: #8E8E93 !important;
      }

      &:hover {
        --bg: #EFEFEF;
      }

      &:active {
        --bg: #EAEAEA;
      }
    }

    &.variantText {
      --bg: transparent;
      --text: #4778EE;

      &:disabled {
        --text: rgba(71, 120, 238, 0.46) !important;
      }

      &:hover {
        --text: #3667DD;
      }

      &:active {
        --text: #2C5BCC;
      }
    }

    &.variantOutlined {
      --bg: transparent;
      --text: #4778EE;

      &:disabled {
        --text: rgba(71, 120, 238, 0.46) !important;
      }

      &:hover {
        --text: #3667DD;
      }

      &:active {
        --text: #2C5BCC;
      }
    }
  }

  &.colorDanger {
    &.variantContained {
      @include niceHover;

      --bg-hover: #DB3632;
      --bg: #E23834;
      --text: white;

      &:disabled {
        --bg: #ff9ea0 !important;
      }

      &:active {
        --bg: #D13430;
      }
    }

    &.variantSecondary {
      --bg: #F7F7F7;
      --text: #E23834;

      &:disabled {
        --bg: #EDEDED !important;
        --text: #F2A3A2 !important;
      }

      &:hover {
        --bg: #EFEFEF;
        --text: #DB3632;
      }

      &:active {
        --bg: #EAEAEA;
        --text: #D13430;
      }
    }

    &.variantText {
      --bg: transparent;
      --text: #E23834;

      &:disabled {
        --text: #F2A3A2 !important;
      }

      &:hover {
        --text: #DB3632;
      }

      &:active {
        --text: #D13430;
      }
    }

    &.variantOutlined {
      --bg: transparent;
      --text: #E23834;

      &:disabled {
        --text: #F2A3A2 !important;
      }

      &:hover {
        --text: #DB3632;
      }

      &:active {
        --text: #D13430;
      }
    }
  }


  &.colorMuted {
    &.variantText {
      --bg: transparent;
      --text: #848488;

      &:disabled {
        --text: #cbcbcd !important;
      }

      &:hover {
        --text: #848488;
      }

      &:active {
        --text: #7E7E83;
      }
    }

    &.variantOutlined {
      --bg: transparent;
      --text: #848488;

      &:disabled {
        --text: #cbcbcd !important;
      }

      &:hover {
        --text: #848488;
      }

      &:active {
        --text: #7E7E83;
      }
    }
  }

  &.colorGreen {
    &.variantContained {
      @include niceHover;

      --bg-hover: #5dd98c;
      --bg: #3EBF71;
      --text: #fff;

      &:disabled {
        --bg: #5dd98c !important;
      }

      &:active {
        --bg: #3EBF71;
      }
    }
  }


  &.colorGreen2 {
    &.variantContained {
      @include niceHover;

      --bg-hover: #5b8c7f;
      --bg: #68A192;
      --text: #fff;
      box-shadow: 0px 49px 20px rgba(104, 161, 146, 0.01), 0px 28px 17px rgba(104, 161, 146, 0.05), 0px 12px 12px rgba(104, 161, 146, 0.09), 0px 3px 7px rgba(104, 161, 146, 0.1), 0px 0px 0px rgba(104, 161, 146, 0.1);

      &:disabled {
        --bg: #7c9b93 !important;
      }

      &:active {
        --bg: #5b8c7f;
      }
    }
  }

  &.colorWhite {
    &.variantContained {
      --bg: #FFFFFF;
      --text: #4778EE;
      box-shadow: 0px 3px 7px 0px #0000001A,
        0px 13px 13px 0px #00000017,
        0px 29px 17px 0px #0000000D,
        0px 51px 21px 0px #00000003,
        0px 80px 23px 0px #00000000;

      &:disabled {
        --bg: #eeeeee !important;
      }

      &:active {
        --bg: #f3f0f0;
      }
    }
  }

  &.variantOutlined {
    --bg: transparent;
    --text: #3EBF71;

    &:disabled {
      --text: #5dd98c !important;
    }

    &:hover {
      --text: #5dd98c;
    }

    &:active {
      --text: #3EBF71;
    }
  }

  &.variantText {
    --bg: transparent;
    --text: #3EBF71;

    &:disabled {
      --text: #5dd98c !important;
    }

    &:hover {
      --text: #5dd98c;
    }

    &:active {
      --text: #3EBF71;
    }
  }
}

.fullWidth {
  width: 100%;
}
