$star-width: 8px;

.label {
  display: inline-flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #272A36;

  padding-bottom: 4px;
  position: relative;
  white-space: nowrap;

  //text-overflow: ellipsis;
  //white-space: nowrap;
  //overflow: hidden;

  width: 100%;
}

.text {
  max-width: 100%;

  &WithStar {
    max-width: calc(100% - #{$star-width});
  }

  display: inline-block;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.star {
  text-align: right;
  width: $star-width;
  color: #E23834;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  user-select: none;
  pointer-events: none;
}
