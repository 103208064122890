.root {
  color: #272A36;
  font-size: 20px;
  font-weight: 500;

  text-align: center;

  overflow: hidden;
}

.word {
  margin-right: 5px;
  display: inline-block;
}
