
.blockHeading {
  width: 100%;
  padding: 4px 16px;
  user-select: none;

  color: #8E8E93;
  background: #F7F7F7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
