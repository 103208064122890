@import '@/assets/scss/mixins.scss';

.messagePreview {
  max-width: 100%;
  min-width: 1px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: #8E8E93;

  min-height: 18px;

  @include ellipsis;

  &__name {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #272A36;
    margin-right: 4px;
  }

  &__content {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    max-width: 100%;
    min-width: 1px;

    @include ellipsis;
  }

  &Deleted {
    display: inline-flex;
    align-items: center;
    flex-shrink: 1;

    &Icon {
      margin-right: 6px;
    }
  }

  &System {
    max-width: 100%;
    @include ellipsis;
  }

  &Draft {
    max-width: 100%;
    @include ellipsis;

    &__label {
      color: #E59F42;
    }

    &__content {
      padding-left: 2px;
    }
  }
}

.contentEmpty {
  flex-grow: 1;

  &:empty {
    display: none;
  }
}

.avatarUnreadMessageCount {
  position: absolute;
  right: -2px;
  bottom: 0;
  z-index: 10;
}
