.root {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo {
  padding-top: 40px;
  width: 92px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.title {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin-bottom: 26px;
}

.image {
  width: 180px;
  max-width: 90vw;
  margin: auto;
}

.content {
  text-align: center;
  margin: 100px 0 50px;
  padding: 0 20px;
}

.subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.description {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #8E8E93;
}
