.loader {
  height: 100vh;
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  min-width: 334px;
  max-width: 335px;

  height: 100vh;
  min-height: 100%;

  position: sticky;
  top: 0;

  border-right: 1px solid #E2E2E2;
  padding: 24px;
  overflow-x: hidden;

  &__docs {
    margin-top: auto;
  }

  &__list {
    width: 100%;
  }

  &__back {
    align-self: flex-start;
    border-radius: 4px;
    padding: 4px 24px 4px 16px;
    background: #F7F7F7;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: auto;
    color: #8E8E93;
    text-decoration: none;
    border: 0;


    &Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      width: 16px;
      height: 16px;
    }
  }

  &__company {
    display: flex;
    align-items: center;
    margin: 32px 0;
    max-width: 100%;
    text-align: left;

    &Avatar {
      min-width: 48px;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%);
    border: 1px solid #E5E6EE;
    border-radius: 4px;

    &Img {
      width: 39px;
      height: 36px;
    }

    &Text {
      font-family: Roboto, serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #000;
      margin-left: 12px;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }
}

.menuItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 8px;
  padding: 8px 16px;
  cursor: pointer;
  color: #272A36;
  text-decoration: none;
  &__statuses {
    display: flex;
    margin-left: 32px;
    margin-top: 7px;
  }
  &__status {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      margin-right: 8px;
    }
    &Pending {
      color: #3EBF71;
      margin-left: 4px;
    }
    &Await {
      color: #E59F42;
      margin-left: 4px;
    }
    &Error {
      color: #E23834;
      margin-left: 4px;
    }
  }
  &__link {
    text-decoration: none;
  }
  &__text {
    display: flex;
    align-items: center;
  }

  &Active {
    border-radius: 12px;
    background-color: #F0F4FE;
    color: #4778EE;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  &:hover, &Active {
    color: #4778EE;

    .menuItem__icon path {
      fill: #4778EE !important;
    }
  }

  &Disabled {
    color: #c8cbde !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    cursor: default;

    &:hover .menuItem__icon path {
      fill: #c8cbde !important;
    }
  }
}

.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: Roboto, serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 12px;
}

.header {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #E2E2E2;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  display: none;

  &__menu {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
    cursor: pointer;
  }

  &__logoImg {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }
}

.company {
  display: flex;
  align-items: stretch;
  height: 100%;
  flex-grow: 1;

  &__main {
    padding: 24px;
    width: 984px + 48px;
    margin: 0 auto;
  }

  &__dialog {
    width: 100%;
    background: #FAFBFC;
  }

  &__notify {
    background: linear-gradient(0deg, #F0F4FE, #F0F4FE), #4778EE;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    margin-bottom: 24px;
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;

    &Icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  &__logo {
    padding: 32px 28px 32px 27px;
    border: 1px solid #E5E6EE;
    border-radius: 20px;
    margin-right: 24px;

    &Img {
      height: 96px;
      width: 105px;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &Head {
      font-family: Roboto, serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0;
      text-align: left;
      color: #272A36;
      margin-bottom: 6px;
    }

    &Value {
      font-family: Roboto, serif;
      font-size: 20px;
      font-weight: 500;
      line-height: 23px;
      letter-spacing: 0;
      text-align: left;
      color: #272A36;
    }
  }
}

@media (max-width: 1366px) {
  .company__main {
    width: calc(100% - 334px);
  }
}

@media (max-width: 672px) {
  .sidebar {
    display: none;
  }
  .header {
    display: flex;
  }
  .company__main {
    margin-top: 64px;
    padding: 15px 12px;
    width: 100%;
  }
  .sidebar_showMobile {
    display: flex;
    padding: 16px;
    position: fixed;
    background: #fff;
    z-index: 100;
    width: 100%;
    max-width: none;
    margin-top: 64px;
    height: auto;
  }
  .sidebar__back {
    margin-bottom: 8px;
  }
}


@media (max-width: 600px) {
  .sidebar_showMobile {
    display: flex;
    padding: 16px;
    position: fixed;
    background: #fff;
    z-index: 100;
    width: 100%;
    max-width: none;
    margin-top: 63px;
    height: auto;
    min-width: auto;
  }
}
