.root {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  text-decoration: none;
  //user-select: none;
  cursor: pointer;

  white-space: nowrap;
}

.label {
  color: #8E8E93;
  font-size: 12px;
  font-weight: 400;
  margin-right: 2px;
}

.profile {
  color: #4778EE;
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
