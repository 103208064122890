@import '@/assets/scss/mixins.scss';

.chats {
  max-height: 100%;
  //overflow-y: scroll;
  //overflow-x: hidden;
  overscroll-behavior-y: none;
  flex-grow: 1;


  //touch-action: none;

  //@include invisible-scroll;

  &__list {
    //@include invisible-scroll;

    overscroll-behavior-y: none;

    //flex-grow: 1;
    //display: flex;
    //flex-direction: column;
    //align-items: stretch;
  }
}
