.root {
  border-radius: 12px;
  position: relative;

  --mask-to-color: rgba(1,1,1,0);

  &:before {
    content: '';

    border: 1px solid rgb(235, 236, 242);
    mask-image: linear-gradient(to bottom, #000, var(--mask-to-color));

    border-radius: inherit;

    pointer-events: none;

    position: absolute;
    width: calc(100%);
    height: calc(100%);
    left: 0;
    top: 0;
  }

  &:hover {
    .icon {
      --color: rgb(71, 120, 238);
    }
  }

  transition: 0.3s all;

  transform: perspective(150px) rotateX(0deg) scale(1);
}

.title {
  cursor: pointer;

  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-align: left;
  padding: 16px;

  &__text {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
  }
}

.icon {
  flex-shrink: 0;
  --color: rgb(142, 142, 147);

  position: relative;

  width: 20px;
  height: 20px;

  border-radius: 100%;
  border: 1.5px solid var(--color);

  $thickness: 1.5px;
  $line-width: 65%;
  transition: 0.3s all;


  &:before {
    content: "";
    background: var(--color);
    border-radius: $thickness / 2;

    width: $line-width;
    height: $thickness;

    position: absolute;
    top: calc(50% - $thickness / 2);
    left: calc((100% - $line-width) / 2);

    transition: 0.3s all;
  }


  &:after {
    content: "";
    background: var(--color);
    border-radius: $thickness / 2;

    width: $thickness;
    height: $line-width;

    position: absolute;
    top: calc((100% - $line-width) / 2) ;
    left: calc(50% - $thickness / 2);

    transform: rotate(0deg);

    transition: 0.3s all;
  }

  &Open {
    --color: rgb(71, 120, 238);

    &:after {
      transform: rotate(90deg);
    }
  }
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: rgb(39, 42, 54);
  padding: 0 24px 16px;
  padding-left: 48px;

  &Wrapper {
    height: 0;
    overflow: hidden;
  }

  //&:not(.descriptionVisible) {
  //  display: none;
  //}
}

.rootDark, .rootDarkOutline {
  .title {
    color: white;
  }

  .description {
    color: #A1A1A1;
  }

  &:before {
    border-color: #3C4049;
  }


  .description {
    li::marker {
      color: white;
    }

    b {
      color: white;
    }
  }
}

.rootDark {
  background: linear-gradient(180deg, #30333D 0%, #272A36 100%);
}

.rootDarkOutline, .rootOutline {
  &:before {
    mask-image: unset;
  }
}

.rootOutline {
  &:before {
    transition: 0.2s border;
  }

  &.rootOpen:before {
    border-color: #abc2f7;
  }
}

.rootCompact {
  .title__text {
    font-size: 16px;
  }
}
