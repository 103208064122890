$max-width: 60%;

$first-border-radius: 8px 8px 8px 0px;
$middle-border-radius: 0px 8px 8px 0px;
$last-border-radius: 0px 8px 8px 8px;

$own-first-border-radius: 8px 8px 0px 8px;
$own-middle-border-radius: 8px 0px 0px 8px;
$own-last-border-radius: 8px 0px 8px 8px;

.message {
  &__block {
    //padding: 0px 16px 0;
    padding-left: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    min-width: 0;

    &Own {
      padding-left: 0px;
      padding-right: 16px;

      justify-content: flex-end;
    }
  }

  &__main {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    min-width: 0;
    //width: 100%;

    &Own {
      align-items: flex-end;
    }
  }

  &__name {
    text-decoration: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #272A36;
    margin-bottom: 2px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;

    transition: 0.3s color;

    &:hover {
      color: #2c5bcc;
    }
  }

  &__prepend {
    flex-shrink: 0;
    padding-right: 8px;
  }
}

.content {
  user-select: text;
  background: #F7F7F7;
  //border-radius: 0px 8px 8px 8px;
  display: inline-flex;
  flex-direction: column;
  font-size: 14px;
  max-width: $max-width;
  align-items: stretch;


  &__prepend {
    padding: 8px 8px 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  &__wrapper {
    background: white;

    user-select: none;
    //padding: 0 16px;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    &:not(:last-child) {
      padding-bottom: 4px;
    }

    &Own {
      justify-content: flex-end;
    }

    &Highlighted {
      background: #FAFBFC;
      transition: 0.3s background;
    }
  }

  &Own {
    background: linear-gradient(0deg, #E8F2FD, #E8F2FD), #4778EE;
    //border-radius: 8px 8px 0px 8px;
  }

  &First {
    border-radius: $first-border-radius;
  }

  &Middle {
    border-radius: $middle-border-radius;
  }

  &Last {
    border-radius: $last-border-radius;
  }

  &OwnFirst {
    border-radius: $own-first-border-radius;
  }

  &OwnMiddle {
    border-radius: $own-middle-border-radius;
  }

  &OwnLast {
    border-radius: $own-last-border-radius;
  }
}
