$height: 25px;

.root {
  display: flex;
  align-items: center;
  height: $height;
  border: 0;
  outline: none;
  background: transparent;
  width: 100%;
  cursor: pointer;
  box-sizing: content-box;
  padding-left: 0;
  padding-right: 0;
  user-select: none;

  &:first-child {
    padding-top: 10px;
  }

  &:last-child {
    padding-bottom: 10px;
  }

  transition: 0.3s all;
}

.icon {
  width: $height;
  height: $height;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
}

.label {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8E8E93;
  padding-right: 2px;
  text-decoration: none;
  padding-left: 12px;
}
