.root {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  padding-left: 170px;

  min-height: 88px;
  display: flex;
  align-items: center;

  background: #2c2e39;

  &:before {
    content: '';

    z-index: -1;
    position: absolute;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    border-radius: inherit;

    border: 1px solid #383c46;

    mask: linear-gradient(140deg, white, transparent 36%);
  }
}

.content {
  flex-grow: 1;
  margin-left: -25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  color: white;

  margin-right: 50px;
}

.image {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.button {
  width: min(100%, 216px);
}


@media(max-width: 700px) {
  .root {
    padding-left: 150px;
  }

  .image {
    transform: translateX(-30%);
  }

  .content {
    padding: 16px 16px 16px 0;

    flex-direction: column;
    align-items: center;
    //justify-content: center;
  }
  .text {
    text-align: center;
    margin-bottom: 8px;
    margin-right: 0;;
  }
}
