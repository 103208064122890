$height: 35px;

.children {
  & > * {
    padding-left: 14px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.root {
  display: flex;
  align-items: center;
  height: $height;
  border: 0;
  outline: none;
  background: transparent;
  width: 100%;
  cursor: pointer;
  box-sizing: content-box;
  padding-left: 0;
  padding-right: 0;

  transition: 0.3s all;

  &:hover {
    background: rgba(230, 230, 230, 0.6);
  }
}

.icon {
  width: $height;
  height: $height;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
}

.label {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #272A36;
  padding-right: 2px;
}

.arrow {
  margin-left: auto;
  margin-right: 16px;
}
