.paper {
  width: 460px;
  max-width: 100%;
  padding-bottom: 30px;
  overflow-x: hidden;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 24px;
}

.image {
  max-width: 100%;
  height: 200px;
  margin: 24px auto 16px;
}

.title {
  padding-top: 0px !important;
  padding-bottom: 16px !important;
}

.content {
  padding: 0 24px;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #272A36;
  margin-bottom: 20px;
}

.note {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #8E8E93;
}

.actions {
  padding: 10px 24px 10px;
  display: flex;
  flex-direction: column;
  align-content: stretch;

  &>* {
    margin-bottom: 16px;
  }
}

.close {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  cursor: pointer;

  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background: transparent;

  transform: rotate(0deg);
  transition: 0.3s all;

  &:hover {
    transform: rotate(180deg);
  }
}
