.close {
  border: 0;
  background: transparent;
  width: 50px;
  height: 50px;
  cursor: pointer;

  .icon {
    transform: rotate(0deg);
    transition: 0.3s all;
  }

  &:hover .icon {
    transform: rotate(180deg);
  }
}
