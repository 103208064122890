@import '../config.module';
@import '@/assets/scss/mixins.scss';

.badges {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 16px;
  gap: 12px;

  .new {
    display: inline-flex;
    padding: 4px;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    line-height: 8px;
    color: #fff;
    background: #E23834;
    border-radius: 4px;
  }
}

.amount {
  color: #fff;
  background-color: #4778EE;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 10px;
  padding: 2px;
  min-width: 18px;
  min-height: 18px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.sidebar {
  padding: 12px 0 24px;
  min-height: 100%;
  position: sticky;
  top: $header-height;
  height: calc(100vh - #{$header-height});
  width: var(--sidebar-width);
  border-right: 1px solid #E2E2E2;
  flex-shrink: 0;
  background: white;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s all;
  z-index: 1;

  &__menu {
    padding: 0 24px;
  }

  &__footer {
    padding: 10px 24px 0;
    width: 100%;
  }

  &Collapsed {
    overflow: hidden;

    .collapse {
      &__icon > * {
        //width: 70px;
        //margin-left: 10px;
        transform: rotate(180deg);
      }

      &__label {
        display: none;
      }
    }

    .menuItem {
      position: relative;

      &__label {
        animation: 0.3s hide-label forwards;
      }

      &__icon {
        width: 100% !important;
        margin-left: 0 !important;
      }
    }
  }
}

.chats {
  margin-top: 26px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__list {
    flex-grow: 1;
  }

  &__placeholder {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__search {
    padding: 0 16px 16px 16px;
    display: flex;
    align-items: center;

    & > :first-child {
      flex-grow: 1;
      max-width: 100%;
    }

    & > * {
      flex-shrink: 0;
    }
  }
}

.collapse {
  width: 100%;
  height: 36px;
  background: #F7F7F7;
  border-radius: 12px;

  border: 0;
  outline: none;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #8E8E93;

  cursor: pointer;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  transition: 0.3s all;
  overflow: hidden;
  user-select: none;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 17px;
    height: 100%;

    & > path {
      fill: #8E8E93;
    }

    & > * {
      transform: rotate(0deg);
      transition: 0.3s all;
    }
  }
}

.privateExchangeInfoIcon {
  width: 24px;
  margin-left: auto;
}

.soundTurn {
  background: transparent;
  border: 0;
  outline: none;
  padding: 0 0 0 8px;
  margin-left: 8px;
  cursor: pointer;
}

.mobileBillingBlock {
  display: flex;
  justify-content: center;

  &>:first-child {
    margin-bottom: 12px;
  }
}

@keyframes hide-label {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    display: none;
  }
}

@media(max-width: $mobile-sidebar-breakpoint-width) {
  .sidebar {
    position: fixed;
    left: 0;
    top: $header-height;
    width: 100%;
    min-height: 100%;
    //display: none;

    z-index: 999;
    background-color: white;

    &__chats {
      max-height: 400px;
    }

    //padding-left: 16px;
    //padding-right: 16px;

    &__content {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .invite {
    margin-top: 3px;
    margin-bottom: 16px;
  }
}
