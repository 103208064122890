.popularLabel {
  font-size: 10px;
  font-weight: 700;
  line-height: 11.72px;
  color: #FFFFFF;

  //position: absolute;
  //top: 0;
  //left: 50%;
  //
  //transform: translate(-50%, -100%);

  background: linear-gradient(128.49deg, #3378FF 0%, #9442FE 100%);
  border-radius: 8px 8px 0 0;
  padding: 6px 10px 4px 10px;
}
