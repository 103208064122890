.input {
  & input {
    margin-bottom: 8px;
    min-width: 150px !important;
  }
  & > div {
    min-height: 43px;
    padding-bottom: 0 !important;
  }
  &__mail {
    height: 24px !important;
    width: 24px !important;
    align-self: flex-start;
  }
}

.input__warning {
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  color: #E59F42;
  padding: 4px 0;
  margin-top: 4px;
}

.input__error {
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  color: #E23834;
  padding: 4px 0;
  margin-top: 4px;
}

.tagEmail {
  display: flex;
  align-items: center;
  padding: 0 8px 0 12px;
  background: #F7F7F7;
  border: 1px solid #F7F7F7;
  border-radius: 100px;
  margin-right: 8px;
  margin-bottom: 8px;
  &__text {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    color: #272A36;
  }
  &__icon {
    cursor: pointer;
    padding-left: 8px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.warning {
    background: #FDF7F0;
    border: 1px solid #E59F42;
    .tagEmail__text {
      color: #E59F42;
    }
  }
  &.error {
    background: #FDEFEF;
    border: 1px solid #E23834;
    .tagEmail__text {
      color: #E23834;
    }
  }
}
