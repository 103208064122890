.content {
  padding: 8px;
  width: 100%;
  display: flex;

  position: relative;
}

.file {
  cursor: pointer !important;
  flex-shrink: 1;
  min-width: 1px;
  overflow: hidden;
}

.attributes {
  position: absolute;
  bottom: 5px;
  right: 7px;
}
