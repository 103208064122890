.root {
  border-top: 1px solid #E5E6EE;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.main {
  display: flex;
  align-items: flex-end;

  .textField {
    flex-grow: 1;
    line-height: 0;
  }

  &>* {
    flex-shrink: 0;
  }
}
