@import '@/assets/scss/mixins.scss';

.file {
  display: flex;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
  min-width: 1px;
  overflow: hidden;

  margin-left: 8px;

  &>:not(:last-child) {
    margin-bottom: 4px;
  }
}

.title {
  color: #272A36;
  font-size: 14px;
  font-weight: 400;

  @include ellipsis;
}

.meta {
  color: #8E8E93;
  font-size: 12px;
  font-weight: 400;

  @include ellipsis;
}

.icon {
  position: relative;

  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 0;

  background: #4778EE;
  border-radius: 4px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &Pointer {
    cursor: pointer;
  }
}

.loading {
  width: calc(100% - 8px);
  height: 2px;

  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.54);

  &__value {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 2px;
    background: white;

    transition: 0.2s all;
  }
}

.iconError {
  position: relative;

  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 0;

  border-radius: 4px;
  border: 1px solid rgba(226, 56, 52, 0.20);
  background: #FADFDF;
}
