@import '@roolz/sdk/assets/mixins.scss';

$text-secondary: #8E8E93;
$background: #F4F4F4;

.filled {
  --border: transparent;
  --background: #F4F4F4;
}

.bordered {
  --border: #E2E2E2;
  --background: transparent;
}

.container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 16px;

  @include applyToChildrenExceptLast(margin-right, 8px);
  @include applyToChildrenExceptLast(margin-bottom, 8px);
}

.text {
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: $text-secondary;
  margin-top: 8px;
}

// TAG
.tag {
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: var(--background);
  border-radius: 4px;
  padding: 8px;
  min-width: 0;

  border: 1px solid var(--border);

  @include applyToChildrenExceptLast(margin-right, 4px);
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: $background;
  border-radius: 4px;
  padding: 2px 4px;

  @include applyToChildrenExceptLast(margin-right, 4px);
}

.flag {
  width: 16px;
  height: 12px;
}

.alpha2 {
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: $text-secondary;
  text-transform: uppercase;
}

.name {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #272A36;
  min-width: 0;

  @include ellipsis;
}

.delete {
  display: inline-flex;
  cursor: pointer;
  color: $text-secondary;
  &:hover {
    color: #4778EE;
  }
}
