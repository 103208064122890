.content {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #8E8E93;

  display: inline-flex;
  align-items: center;

  padding: 8px;
}

.icon {
  margin-right: 6px;
}
