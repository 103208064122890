$sidebar-width: 334px;
$sidebar-collapsed-width: 100px;

$header-height: 62px;


// max width on which should be visible mobile sidebar
$mobile-sidebar-breakpoint-width: 700px;

:root {
  --sidebar-width: #{$sidebar-width};
}

:export {
  mobileSidebarBreakpointWidth: $mobile-sidebar-breakpoint-width;
  sidebarWidth: $sidebar-width;
  sidebarCollapsedWidth: $sidebar-collapsed-width;
}
