@import './config.module';

.root {
  min-height: 100vh;
  //width: 100vw;
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  align-items: stretch;
  height: 100%;
  flex-grow: 1;
}

.content {
  min-width: 1px;
  flex-grow: 1;
  max-width: 100%;
}
