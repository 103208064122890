.calc {
  margin-top: 16px;

  color: #8E8E93;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: center;
}

.amountDescription {
  margin-top: 16px;

  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  color: #272A36;

  &__amount {
    color: #4778EE;
    font-weight: 500;
  }
}
