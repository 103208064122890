@import '@roolz/sdk/assets/mixins.scss';

.card {
  &__edit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px + 48px;
    height: 20px + 48px;
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 0;
    padding: 24px;
  }

  &__title {
    font-family: Roboto, serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0;
    color: #272A36;
  }

  &__text {
    font-family: Roboto, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    color: #272A36;
    margin-top: 16px;
    white-space: pre-wrap;
  }

  &__licenses {
    display: flex;
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;

    &Contact {
      padding-right: 8px;
      min-width: 311px;
    }

    &_contacts {
      justify-content: flex-start;
      width: auto;
    }

    &Label {
      font-family: Roboto, serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      text-align: left;
      color: #8E8E93;
      margin-bottom: 8px;
    }

    &Value {
      font-family: Roboto, serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0;
      color: #272A36;
      text-decoration: none;
      transition: 0.25s all;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }

    &Value_border {
      border: 1px solid #E5E6EE;
      border-radius: 4px;
      padding: 8px;
      font-family: Roboto, serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      text-align: left;
      color: #272A36;
      white-space: pre;
    }
  }
}

.representatives__container {
  margin-top: 24px;
  margin-bottom: 24px;
}

.representatives {
  display: flex;
  flex-direction: column;

  margin: 0 -24px -24px -24px;

  & > * {
    margin: 0 24px;
    flex-grow: 1;
  }

  @include applyToChildrenExceptLast(margin-bottom, 12px);

  @media (max-width: 750px) {
    & {
      overflow-y: unset;
      max-height: unset;
      margin-bottom: 0;
    }
  }
}

.add {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;

  @include applyToChildrenExceptLast(margin-right, 8px);

  &__text {
    color: #4778EE;
    text-align: center;
    font-weight: 500;
  }
}

a.card__tagValue:hover {
  color: #4778EE;
}

.formGroup {
  margin-top: 16px;

  &_directions {
    margin-bottom: 16px;
  }
}

.field {
  &__label {
    font-family: Roboto, serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
    color: #272A36;
    margin: 4px 0;
  }

  &__textarea {
    background: #FFF;
    border: 1px solid #E5E6EE;
    border-radius: 4px;
    resize: none;
    padding: 12px;
    color: #272A36;
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    background: none;
    min-height: 172px;
    overflow-y: auto !important;
    max-height: 200px;

    &:focus {
      outline: none !important;
      border: 1px solid #4778ee;
      border-radius: 4px;
    }
  }
}

.modalEditDescription {
  display: flex;
  flex-direction: column;

  &__buttons {
    display: flex;
    margin-top: 16px;
  }

  &__cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
    padding: 8px 16px;
    width: 83px;
    height: 40px;
    cursor: pointer;
    margin-right: 24px;
    color: #8E8E93;
  }

  &__title {
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    color: #272A36;
  }
}

.modal__field {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.description__card {
  border: 1px solid #E5E6EE;
  border-radius: 8px;
  padding: 24px;
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
}

.tags__border {
  display: flex;
  flex-wrap: wrap;
}

.card__tagValue_border {
  display: flex;
  margin-right: 8px;
  margin-bottom: 8px;
  align-items: center;
}

.modalPage {
  position: absolute;
}

.flag {
  width: 16px;
  height: 12px;
  margin-right: 4px;
}

.alfa2 {
  background: #F4F4F4;
  border-radius: 4px;
  padding: 2px 4px;
  margin-right: 4px;

  &__text {
    font-family: Roboto, serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
    color: #8E8E93;
  }
}

@media (max-width: 600px) {
  .card__licenses {
    flex-direction: column;
  }

  .card__tagValue_border:last-of-type {
    margin-right: 0;
  }
}

@media (max-width: 560px) {
  .card__tag {
    width: 100%;

    &Contact {
      min-width: calc(100% - 24px);
    }
  }
}
