@import '@roolz/sdk/assets/mixins.scss';

.headerButton {
  flex-shrink: 1;

  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  column-gap: 8px;
  margin-left: 24px;
  border: 1px solid #E5E6EE;
  border-radius: 8px;
  padding: 5px 8px;
  background: transparent;
  transition: all .3s;
  text-align: left;
  cursor: pointer;

  &:hover {
    border: 1px solid transparent;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 0 6px 0 rgba(0, 0, 0, 0.02);
  }

  svg {
    grid-row: 1 / 3;
  }

  .title {
    font-size: 12px;
    line-height: 14px;
    color: #272A36;

    @include ellipsis;
  }

  .appeal {
    margin-top: 3px;
    font-size: 11px;
    line-height: 13px;
    color: #4778EE;
  }
}

@media (max-width: 1000px) {
  .root + .root {
    display: none;
  }
}

@media (max-width: 825px) {
  .root {
    display: none;
  }
}
