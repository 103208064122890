.root {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.button {
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.icon {
  position: relative;
}
