.root {
  margin: 60px auto 0;

  max-width: min(1320px, 100%);
  //overflow-x: auto;

  @media(max-width: 1000px) {
    margin-top: 20px;
  }
}

.table {
  border: 1px solid #EBECF2;
  background: #F8F9FC;
  border-collapse: collapse;
  border-spacing: 0;

  width: 100%;
  margin: 0 auto;

  th {
    background: white;

    border-style: solid;
    border-color: #EBECF2;
    border-width: 0 0 1px 0;
    //border-bottom-width: 1px;
    //border-top-width: 0;
    //border-left-width: 0;
    //border-right: 0;

    &:not(:last-child) {
      border-right-width: 1px;
    }
  }
}

.chapter {
  background: #EDF2FD;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center;
  color: #000000;
  padding: 12px;
}

.tableBody {
  td {
    width: calc(100% / var(--cols-count));

    border-style: solid;
    border-color: #EBECF2;
    //border-bottom-width: 1px;
    //border-top-width: 0;
    //border-left-width: 0;
    //border-right: 0;
    border-width: 0 0 1px 0;

    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;

    padding: 14px;

    &:not(:last-child) {
      border-right-width: 1px;
    }
  }

  tr {
    &:nth-child(even) {
      background: #FFFFFF;
    }
  }

  i {
    color: #6C7090;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
  }

  less:before {
    content: '<';
  }

  muted {
    color: #6C7090;
  }

  i {
    color: #6C7090;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
  }

  b {
    font-weight: 500;
  }
}

.planName {
  padding: 10px 12px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  color: #272A36;

  position: relative;

  &__label {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}

.rowName {
  height: 100%;

  font-size: 14px;
  font-weight: 400;
  color: #272A36;

  //text-align: center;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &__tooltip {
    padding: 0 17px;
  }
}

.tdValue {
  font-size: 14px;
  text-align: center;
}

.planBottomButton {
  border: 0;

  padding: 14px 5px;
  width: 100%;
  height: 100%;
  cursor: pointer;

  background: white;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #4778EE;

  &Filled {
    background: #F8F9FC;
  }

  &Current {
    background: #F8F9FC;
    color: #272A36;
    font-weight: 700;

    pointer-events: none;
  }

  &Hidden {
    visibility: hidden;
  }
}

.tdFill {
  background: #F8F9FC !important;
}

.topActionCell {
  border-top: 0 !important;
  padding: 0 11px 11px;
}

.topAction {
  padding: 12px;
  width: 100%;
}
