.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  width: 300px;
  max-width: 100%;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #272A36;
  margin-top: 16px;
  margin-bottom: 24px;
}

.list {
  &__item {
    &:not(:last-child) {
      margin-bottom: 18px;
    }

    text-align: left;
    display: flex;
    align-items: flex-start;

    &__icon {
      margin-right: 10px;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #272A36;
    }
  }
}
