.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  background: #fff;
  padding-top: 24px;
}

.formGroup {
  margin-top: 16px;
  width: 100%;

  &_checkbox {
    margin-top: 4.5px;
  }
}

.formBlock {
  margin-bottom: 16px;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &__label {
    color: #8E8E93;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    cursor: pointer;
  }
}