.root {
  text-align: center;
  padding-top: 60px;
  //max-width: 100%;
  //overflow: hidden;
}

.type {
  position: relative;
  display: inline-block;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;

}

@media (max-width: 700px) {
  .type {
    margin: auto;
    width: 85%;
  }

  .selectType {
    width: 100%;

    &__item {
      padding-left: 7px;
      padding-right: 7px;
    }
  }
}
