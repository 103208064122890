.overlay {
  background: rgba(255,255,255, 0.6);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &Fixed {
    z-index: 999999;

    position: fixed;
    top: 0;
    left: 0;

    width: 100svw !important;
    height: 100svh !important;
  }
}
