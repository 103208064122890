.root {
  border-radius: 12px;
  border: 1px solid rgb(235, 236, 242);
  padding: 16px;

  color: rgb(142, 142, 147);

  display: flex;
  align-items: center;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
}

.icon {
  margin-right: 8px;
}
