.root {
  position: relative;
  border-radius: 12px;
  padding: 24px;
  z-index: 1;
  overflow: hidden;

  &:before {
    content: '';

    z-index: -1;
    position: absolute;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;

    border: 1px solid #3C4049;
    background: #30323d;

    mask: linear-gradient(140deg, white, transparent 36%);
  }
}

.icon {
  color: #4778EE;
}

.title {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  color: #FFFFFF;
}

.description {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: #A1A1A1;
}
