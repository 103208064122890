.root {
  position: sticky;
  //z-index: 10;
  top: 10px;
  //margin-top: 10px;
  width: 100%;
  padding: 8px 0;
  z-index: 1;

  display: flex;
  justify-content: center;
  pointer-events: none;

  opacity: 1;

  &Invisible {
    opacity: 0;
    pointer-events: none;

    transition: 0.7s 0.5s opacity;
    //animation: 0.5s 0.5s fade forwards;
  }
}

.item {
  //position: absolute;
  //top: 0;
  //left: 0;
  //transform: translateX(-50%);

  user-select: none;
  padding: 4px 8px;
  display: inline-block;
  background: white;
  border-radius: 12px;
}

.text {
  color: #8E8E93;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

//@keyframes fade {
//  from {
//    opacity: 1;
//  }
//  to {
//    opacity: 0;
//  }
//}
