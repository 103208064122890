.associations {
  position: relative;

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    isolation: isolate;
    width: 352px;
    height: 309px;
    background: #FFF;
    border: 1px solid #4778EE;
    box-shadow: 0 0.878477px 1.75695px rgba(0, 0, 0, 0.2),
    0 1.75695px 1.75695px rgba(0, 0, 0, 0.14),
    0 0.878477px 2.63543px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    z-index: 100000;
  }
}

.menu {
  &__title {
    font-family: Roboto, serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
    text-align: center;
    color: #8E8E93;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 12px 4px;
    width: 100%;
  }

  &__item {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 8px 12px;
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    color: #272A36;
    justify-content: space-between;
    width: 100%;
    padding-right: 1px;
  }

  &__items {
    width: 100%;
    margin-top: 39px;
    overflow-y: auto;
  }

  &__search {
    width: 100%;
    position: fixed;

    &Input {
      width: 100%;
    }
    &Clear {
      cursor: pointer;
    }
  }
}

.association__items {
  display: flex;
  flex-wrap: wrap;
}

.association {
  background: #F4F4F4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding-left: 8px;
  margin-bottom: 8px;

  &__delete {
    padding: 8px;
    padding-left: 4px;
    cursor: pointer;
  }

  &__title {
    font-family: Roboto, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    color: #272A36;
  }

  &__add {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px dashed #E5E6EE;
    border-radius: 8px;
  }
}

.countryTag__flag {
  width: 16px;
  margin-right: 12px;
}

.countryTag__label {
  padding-right: 4px;
}
