@import '@roolz/sdk/assets/mixins.scss';
$text-secondary: #8E8E93;
$background: #F4F4F4;

.filled {
  --border: transparent;
  --background: #F4F4F4;
}

.bordered {
  --border: #E2E2E2;
  --background: transparent;
}

.tag {
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: var(--background);
  border-radius: 4px;
  padding: 8px;
  min-width: 0;

  border: 1px solid var(--border);

  @include applyToChildrenExceptLast(margin-right, 4px);
}

.name {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #272A36;
  min-width: 0;

  @include ellipsis;
}

.delete {
  display: inline-flex;
  cursor: pointer;
  color: $text-secondary;
  &:hover {
    color: #4778EE;
  }
}
