.input {
  width: 100%;
  height: 39px;
}

.content {
  width: 424px + 48px;
  padding: 24px;
  margin: 48px auto 0;
}

.formGroup {
  margin-top: 16px;

  &_checkbox {
    margin-top: 4.5px;
  }
}

.transportLicense {
  display: flex;
  margin-bottom: 4.5px;
  padding: 3.5px;
}

.legalEntity {
  display: flex;
  margin: 8px 0 24px;
  padding: 3.5px;
}

.forwarderLicense {
  display: flex;
  padding: 3.5px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
}

.buttons {
  display: flex;
}

.cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  margin-right: 24px;
  color: #8E8E93;
  text-decoration: none;
  cursor: pointer;
}

.inputShort {
  width: calc(50% - 8px);
}

.inputsDouble {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.formBlock {
  margin-top: 24px;
}

.deleteIcon {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  &__description {
    margin-top: 16px;
  }

  &__actions {
    display: flex;
    align-items: center;
    padding: 23px;
    justify-content: flex-end;
    flex: 0 0 auto;
    background: #fff;
    padding-top: 3px;
  }

  &__action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    height: 36px;
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4778EE;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-right: 4px;
    }

    &Red {
      color: #E23834;
    }
  }
}

@media (max-width: 672px) {
  .content {
    margin-top: 0;
    padding: 0;
    width: 100%;
  }
}
