@import '@roolz/sdk/assets/mixins.scss';

.yearPricePanel {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #6C7090;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 14.06px;

  border: 1px solid #EBECF2;
  border-radius: 4px;
  padding: 5px;

  visibility: hidden;

  margin-top: 8px;

  @include ellipsis;

  &Visible {
    visibility: visible;
  }

  svg {
    margin-right: 4px;
  }
}
