.content {
  position: relative;
  min-height: 1px;
  flex-grow: 1;
  overflow-y: hidden;
}

.messages {
  overscroll-behavior-y: none;

  height: 100%;
  width: 100%;
  overflow-y: scroll;
  position: relative;

  &__content {
    min-height: 100.5%;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;
  }

  &Mobile {
    overscroll-behavior-y: unset;
  }
}

.scrollToEnd {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
}

.placeholder {
  height: 100%;
  width: 100%;
}

.messagesByDay {
  position: relative;
  //margin-bottom: -16px;
  //padding-bottom: 16px;
}

.errorPlaceholder {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__image {
    max-width: 50%;
    max-height: 50%;
  }
}
